import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../config/Axios/axios-instance';
import { AffiliateUser, AffiliateUserStats } from '../../domain/Affiliate';

export const fetchAffiliateUserList = createAsyncThunk('affiliate/userList', async () => {
  const response = await axiosInstance.get<AffiliateUser[]>(`/affiliate/users`);
  return response.data;
});

export const fetchAffiliateUserStats = createAsyncThunk('affiliate/userStats', async (userId: number) => {
  const response = await axiosInstance.get<AffiliateUserStats>(`/affiliate/${userId}`);
  return response.data;
});

import React, { ReactNode } from 'react';
import styles from './Layout.module.scss';
import Navigation from '../Navigation/Navigation';
import Sidebar from '../Sidebar/Sidebar';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';

export type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
};

const MOBILE_BREAK_POINT = 1200;

const Layout = ({ children, isAuthenticated }: Props) => {
  const { width } = useWindowSize();

  if (!isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <div className={styles.layout}>
      <Navigation />
      {width && width >= MOBILE_BREAK_POINT && <Sidebar />}
      <div className={styles.withSidebar}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;

import { HttpError } from '../../config/Axios/axios-instance';
import { Blueprint, BlueprintsList } from '../../domain/Blueprint';
import * as actionTypes from './actionTypes';
import { BlueprintActionTypes } from './actionTypes';

export type BlueprintStateType = {
  blueprints: BlueprintsList | null;
  blueprintsLoading: boolean;
  blueprintsError: HttpError;
  blueprint: Blueprint | null;
  blueprintLoading: boolean;
  blueprintError: HttpError;
  blueprintUpdateLoading: boolean;
  blueprintUpdateError: HttpError;
  blueprintUpdateSuccess: boolean;
  blueprintCreateLoading: boolean;
  blueprintCreateError: HttpError;
  blueprintCreateSuccess: boolean;
  createdBlueprint: Blueprint | null;
};

export type BlueprintActionType = BlueprintStateType & {
  type: BlueprintActionTypes;
};

export const initialState: BlueprintStateType = {
  blueprints: null,
  blueprintsLoading: false,
  blueprintsError: null,
  blueprint: null,
  blueprintLoading: false,
  blueprintError: null,
  blueprintUpdateLoading: false,
  blueprintUpdateError: null,
  blueprintUpdateSuccess: false,
  blueprintCreateLoading: false,
  blueprintCreateError: null,
  blueprintCreateSuccess: false,
  createdBlueprint: null,
};

const fetchBlueprintsStart = (state: BlueprintStateType): BlueprintStateType => ({
  ...state,
  blueprintsLoading: true,
  blueprint: null,
  blueprintCreateSuccess: false,
  blueprintUpdateSuccess: false,
});

const fetchBlueprintsSuccess = (state: BlueprintStateType, action: BlueprintActionType): BlueprintStateType => ({
  ...state,
  blueprints: action.blueprints,
  blueprintsLoading: false,
  blueprintsError: null,
});

const fetchBlueprintsFail = (state: BlueprintStateType, action: BlueprintActionType): BlueprintStateType => ({
  ...state,
  blueprintsError: action.blueprintsError,
  blueprintsLoading: false,
});

const fetchBlueprintStart = (state: BlueprintStateType): BlueprintStateType => ({
  ...state,
  blueprintLoading: true,
  blueprintCreateSuccess: false,
  blueprintUpdateSuccess: false,
  blueprintUpdateError: null,
  blueprintCreateError: null,
});

const fetchBlueprintSuccess = (state: BlueprintStateType, action: BlueprintActionType): BlueprintStateType => ({
  ...state,
  blueprint: action.blueprint,
  blueprintLoading: false,
  blueprintError: null,
});

const fetchBlueprintFail = (state: BlueprintStateType, action: BlueprintActionType): BlueprintStateType => ({
  ...state,
  blueprintError: action.blueprintError,
  blueprintLoading: false,
});

const updateBlueprintStart = (state: BlueprintStateType): BlueprintStateType => ({
  ...state,
  blueprintUpdateLoading: true,
});

const updateBlueprintSuccess = (state: BlueprintStateType): BlueprintStateType => ({
  ...state,
  blueprintUpdateLoading: false,
  blueprintUpdateError: null,
  blueprintUpdateSuccess: true,
});

const updateBlueprintFail = (state: BlueprintStateType, action: BlueprintActionType): BlueprintStateType => ({
  ...state,
  blueprintUpdateLoading: false,
  blueprintUpdateError: action.blueprintUpdateError,
});

const createBlueprintStart = (state: BlueprintStateType): BlueprintStateType => ({
  ...state,
  blueprintCreateLoading: true,
});

const createBlueprintSuccess = (state: BlueprintStateType, action: BlueprintActionType): BlueprintStateType => ({
  ...state,
  blueprintCreateLoading: false,
  blueprintCreateError: null,
  blueprintCreateSuccess: true,
  createdBlueprint: action.createdBlueprint,
});

const createBlueprintFail = (state: BlueprintStateType, action: BlueprintActionType): BlueprintStateType => ({
  ...state,
  blueprintCreateLoading: false,
  blueprintCreateError: action.blueprintCreateError,
});

const reducer = (state = initialState, action: BlueprintActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_BLUEPRINTS_START:
      return fetchBlueprintsStart(state);
    case actionTypes.FETCH_BLUEPRINTS_SUCCESS:
      return fetchBlueprintsSuccess(state, action);
    case actionTypes.FETCH_BLUEPRINTS_FAIL:
      return fetchBlueprintsFail(state, action);
    case actionTypes.FETCH_BLUEPRINT_START:
      return fetchBlueprintStart(state);
    case actionTypes.FETCH_BLUEPRINT_SUCCESS:
      return fetchBlueprintSuccess(state, action);
    case actionTypes.FETCH_BLUEPRINT_FAIL:
      return fetchBlueprintFail(state, action);
    case actionTypes.UPDATE_BLUEPRINT_START:
      return updateBlueprintStart(state);
    case actionTypes.UPDATE_BLUEPRINT_SUCCESS:
      return updateBlueprintSuccess(state);
    case actionTypes.UPDATE_BLUEPRINT_FAIL:
      return updateBlueprintFail(state, action);
    case actionTypes.CREATE_BLUEPRINT_START:
      return createBlueprintStart(state);
    case actionTypes.CREATE_BLUEPRINT_SUCCESS:
      return createBlueprintSuccess(state, action);
    case actionTypes.CREATE_BLUEPRINT_FAIL:
      return createBlueprintFail(state, action);
    default:
      return state;
  }
};

export default reducer;

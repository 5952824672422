import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../config/Axios/axios-instance';
import { ListParams } from '../../hooks/useList/useList';
import { AvailableResource, Resource, ResourceList, ResourceRarity } from '../../domain/Resource';

export type ResourceCreateRequest = {
  title: string;
  key: string;
  icon?: File;
  rarity: ResourceRarity;
};

export type ResourceUpdateRequest = {
  id: number;
  title: string;
  rarity: ResourceRarity;
  icon?: File;
  iconId?: number;
};

export const fetchAvailableResources = createAsyncThunk('resource/available', async () => {
  const response = await axiosInstance.get<AvailableResource[]>(`/resource`);
  return response.data;
});

export const fetchResourceList = createAsyncThunk('resource/list', async (params?: ListParams) => {
  const response = await axiosInstance.get<ResourceList>(`/resource/all`, { params });
  return response.data;
});

export const fetchResource = createAsyncThunk('resource/item', async (id: number) => {
  const response = await axiosInstance.get<Resource>(`/resource/${id}`);
  return response.data;
});

export const createResource = createAsyncThunk('resource/create', async (inputs: ResourceCreateRequest) => {
  const form = createForm(inputs);

  const response = await axiosInstance.post('/resource', form);
  return response.data;
});

export const updateResource = createAsyncThunk('resource/update', async (inputs: ResourceUpdateRequest) => {
  const form = createForm(inputs);

  const response = await axiosInstance.patch(`/resource/${inputs.id}`, form);
  return response.data;
});

const createForm = (inputs: ResourceCreateRequest | ResourceUpdateRequest): FormData => {
  const form = new FormData();
  Object.keys(inputs).forEach((inputKey) => {
    // @ts-ignore
    const value = inputs[inputKey];

    if (inputKey === 'icon') {
      if (value !== undefined) {
        form.append(inputKey, value);
      }
    } else {
      if (value !== undefined) {
        form.append(inputKey, value);
      }
    }
  });
  return form;
};

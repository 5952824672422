import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  createCraftingRecipe,
  fetchAvailableCraftingRecipes,
  fetchCraftingRecipe,
  fetchCraftingRecipeList,
  updateCraftingRecipe,
} from './actions';
import { CraftingRecipe, CraftingRecipesList } from '../../domain/CraftingRecipe';

interface CraftingRecipeStateType {
  craftingRecipeList: CraftingRecipesList | null;
  craftingRecipeListLoading: boolean;
  craftingRecipeListError: HttpError;
  craftingRecipe: CraftingRecipe | null;
  craftingRecipeLoading: boolean;
  craftingRecipeError: HttpError;
  craftingRecipeUpdateLoading: boolean;
  craftingRecipeUpdateError: HttpError;
  craftingRecipeCreateLoading: boolean;
  craftingRecipeCreateError: HttpError;
  availableCraftingRecipes: CraftingRecipe[];
  availableCraftingRecipesLoading: boolean;
  availableCraftingRecipesError: HttpError;
}

const initialState: CraftingRecipeStateType = {
  craftingRecipeList: null,
  craftingRecipeListLoading: false,
  craftingRecipeListError: null,
  craftingRecipe: null,
  craftingRecipeLoading: false,
  craftingRecipeError: null,
  craftingRecipeUpdateLoading: false,
  craftingRecipeUpdateError: null,
  craftingRecipeCreateLoading: false,
  craftingRecipeCreateError: null,
  availableCraftingRecipes: [],
  availableCraftingRecipesLoading: false,
  availableCraftingRecipesError: null,
};

const craftingRecipeSlice = createSlice({
  name: 'craftingRecipe',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCraftingRecipeList.fulfilled.type, (state, action: PayloadAction<CraftingRecipesList>) => {
      state.craftingRecipeList = action.payload;
      state.craftingRecipeListLoading = false;
      state.craftingRecipeListError = null;
    });
    builder.addCase(fetchCraftingRecipeList.pending.type, (state) => {
      state.craftingRecipeListLoading = true;
      state.craftingRecipeCreateError = null;
      state.craftingRecipeUpdateError = null;
      state.craftingRecipeCreateLoading = false;
      state.craftingRecipeUpdateLoading = false;
    });
    builder.addCase(fetchCraftingRecipeList.rejected.type, (state, action: PayloadAction<HttpError>) => {
      state.craftingRecipeListLoading = false;
      state.craftingRecipeListError = action.payload;
    });

    builder.addCase(fetchAvailableCraftingRecipes.fulfilled.type, (state, action: PayloadAction<CraftingRecipe[]>) => {
      state.availableCraftingRecipes = action.payload;
      state.availableCraftingRecipesLoading = false;
      state.availableCraftingRecipesError = null;
    });
    builder.addCase(fetchAvailableCraftingRecipes.pending.type, (state) => {
      state.availableCraftingRecipesLoading = true;
    });
    builder.addCase(fetchAvailableCraftingRecipes.rejected.type, (state, action: PayloadAction<HttpError>) => {
      state.availableCraftingRecipesLoading = false;
      state.availableCraftingRecipesError = action.payload;
    });

    builder.addCase(fetchCraftingRecipe.fulfilled.type, (state, action: PayloadAction<CraftingRecipe>) => {
      state.craftingRecipe = action.payload;
      state.craftingRecipeLoading = false;
      state.craftingRecipeError = null;
    });
    builder.addCase(fetchCraftingRecipe.pending.type, (state) => {
      state.craftingRecipeLoading = true;
    });
    builder.addCase(fetchCraftingRecipe.rejected.type, (state, action: PayloadAction<HttpError>) => {
      state.craftingRecipeLoading = false;
      state.craftingRecipeError = action.payload;
    });

    builder.addCase(createCraftingRecipe.fulfilled.type, (state) => {
      state.craftingRecipeCreateLoading = false;
      state.craftingRecipeCreateError = null;
    });
    builder.addCase(createCraftingRecipe.pending.type, (state) => {
      state.craftingRecipeCreateError = null;
      state.craftingRecipeCreateLoading = true;
    });
    builder.addCase(createCraftingRecipe.rejected.type, (state, action: PayloadAction<HttpError>) => {
      state.craftingRecipeCreateLoading = false;
      state.craftingRecipeCreateError = action.payload;
    });

    builder.addCase(updateCraftingRecipe.fulfilled.type, (state) => {
      state.craftingRecipeUpdateLoading = false;
      state.craftingRecipeUpdateError = null;
    });
    builder.addCase(updateCraftingRecipe.pending.type, (state) => {
      state.craftingRecipeUpdateError = null;
      state.craftingRecipeUpdateLoading = true;
    });
    builder.addCase(updateCraftingRecipe.rejected.type, (state, action: PayloadAction<HttpError>) => {
      state.craftingRecipeUpdateLoading = false;
      state.craftingRecipeUpdateError = action.payload;
    });
  },
});

export const craftingRecipeActions = craftingRecipeSlice.actions;

const craftingRecipeReducer = craftingRecipeSlice.reducer;
export default craftingRecipeReducer;

import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as languageService from '../../store/language/service';
import * as authService from '../../store/auth/service';
import { Language } from '../../domain/Language';
import { IntlProvider } from 'react-intl';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import { RootState } from '../../store/StoreProvider';
import { fetchAvailableResources } from '../../store/resource/actions';

const Login = React.lazy(() => import('../../pages/Login/Login'));
const Items = React.lazy(() => import('../../pages/Items/Items'));
const ItemEdit = React.lazy(() => import('../../pages/ItemEdit/ItemEdit'));
const ItemCreate = React.lazy(() => import('../../pages/ItemCreate/ItemCreate'));
const Users = React.lazy(() => import('../../pages/Users/Users'));
const UserDetails = React.lazy(() => import('../../pages/UserDetails/UserDetails'));
const Ledger = React.lazy(() => import('../../pages/Ledger/Ledger'));
const SettingsEdit = React.lazy(() => import('../../pages/SettingsEdit/SettingsEdit'));
const ChatMessages = React.lazy(() => import('../../pages/ChatMessages/ChatMessages'));
const Translations = React.lazy(() => import('../../pages/Translations/Translations'));
const Blueprints = React.lazy(() => import('../../pages/Blueprints/Blueprints'));
const BlueprintEdit = React.lazy(() => import('../../pages/BlueprintEdit/BlueprintEdit'));
const BlueprintCreate = React.lazy(() => import('../../pages/BlueprintCreate/BlueprintCreate'));
const Statistics = React.lazy(() => import('../../pages/Statistics/Statistics'));
const HomeBanners = React.lazy(() => import('../../pages/HomeBanners/HomeBanners'));
const HomeBannerEdit = React.lazy(() => import('../../pages/HomeBannerEdit/HomeBannerEdit'));
const HomeBannerCreate = React.lazy(() => import('../../pages/HomeBannerCreate/HomeBannerCreate'));
const Campaigns = React.lazy(() => import('../../pages/Campaigns/Campaigns'));
const CampaignsCreate = React.lazy(() => import('../../pages/CampaignsCreate/CampaignsCreate'));
const CampaignsEdit = React.lazy(() => import('../../pages/CampaignsEdit/CampaignsEdit'));
const Badges = React.lazy(() => import('../../pages/Badges/Badges'));
const BadgeCreate = React.lazy(() => import('../../pages/BadgeCreate/BadgeCreate'));
const BadgeEdit = React.lazy(() => import('../../pages/BadgeEdit/BadgeEdit'));
const Levels = React.lazy(() => import('../../pages/Levels/Levels'));
const LevelCreate = React.lazy(() => import('../../pages/LevelCreate/LevelCreate'));
const LevelEdit = React.lazy(() => import('../../pages/LevelEdit/LevelEdit'));
const MCCDiscounts = React.lazy(() => import('../../pages/MCCDiscounts/MCCDiscounts'));
const MCCDiscountCreate = React.lazy(() => import('../../pages/MCCDiscountCreate/MCCDiscountCreate'));
const MCCDiscountEdit = React.lazy(() => import('../../pages/MCCDiscountEdit/MCCDiscountEdit'));
const LandDiscounts = React.lazy(() => import('../../pages/LandDiscounts/LandDiscounts'));
const LandDiscountCreate = React.lazy(() => import('../../pages/LandDiscountCreate/LandDiscountCreate'));
const LandDiscountEdit = React.lazy(() => import('../../pages/LandDiscountEdit/LandDiscountEdit'));
const ColonistParts = React.lazy(() => import('../../pages/ColonistParts/ColonistParts'));
const ColonistPartCreate = React.lazy(() => import('../../pages/ColonistPartCreate/ColonistPartCreate'));
const ColonistPartEdit = React.lazy(() => import('../../pages/ColonistPartEdit/ColonistPartEdit'));
const BlueprintMysteryBox = React.lazy(() => import('../../pages/BlueprintMysteryBox/BlueprintMysteryBox'));
const Affiliate = React.lazy(() => import('../../pages/Affiliate/Affiliate'));
const AffiliateUserEdit = React.lazy(() => import('../../pages/AffiliateUserEdit/AffiliateUserEdit'));
const JumpTask = React.lazy(() => import('../../pages/JumpTask/JumpTask'));
const CraftingRecipes = React.lazy(() => import('../../pages/CraftingRecipes/CraftingRecipes'));
const CraftingRecipeCreate = React.lazy(() => import('../../pages/CraftingRecipeCreate/CraftingRecipeCreate'));
const CraftingRecipeEdit = React.lazy(() => import('../../pages/CraftingRecipeEdit/CraftingRecipeEdit'));
const Resources = React.lazy(() => import('../../pages/Resources/Resources'));
const ResourceEdit = React.lazy(() => import('../../pages/ResourceEdit/ResourceEdit'));
const ResourceCreate = React.lazy(() => import('../../pages/ResourceCreate/ResourceCreate'));
const MarsBattleBlocks = React.lazy(() => import('../../pages/MarsBattleBlocks/MarsBattleBlocks'));
const MarsBattleBlockEdit = React.lazy(() => import('../../pages/MarsBattleBlockEdit/MarsBattleBlockEdit'));
const MarsBattleBlockCreate = React.lazy(() => import('../../pages/MarsBattleBlockCreate/MarsBattleBlockCreate'));

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  languageLoading: boolean;
  onLanguageInit: (locale: string) => void;
  onResourceInit: () => void;
  language: Language | null;
};

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  onLanguageInit,
  onResourceInit,
  language,
  languageLoading,
}: Props) => {
  useEffect(() => {
    onTryAutoSignup();
  }, [onTryAutoSignup]);

  useEffect(() => {
    onLanguageInit('en');
    onResourceInit();
  }, []);

  const mappedTranslations = language?.translations.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  return (
    <BrowserRouter basename="/">
      {isInitCompleted && !languageLoading ? (
        <IntlProvider messages={mappedTranslations} locale={language?.locale ?? 'en'} defaultLocale="en">
          <Suspense fallback={<Loader isLoading isFullScreen />}>
            <Layout isAuthenticated={isAuthenticated}>
              {!isAuthenticated && (
                <Routes>
                  <Route path={routes.login} element={<Login />} />
                  <Route path="*" element={<Navigate to={routes.login} />} />
                </Routes>
              )}
              {isAuthenticated && (
                <Routes>
                  <Route path={routes.items} element={<Items />} />
                  <Route path={routes.itemCreate} element={<ItemCreate />} />
                  <Route path={routes.itemEdit} element={<ItemEdit />} />
                  <Route path={routes.users} element={<Users />} />
                  <Route path={routes.usersId} element={<UserDetails />} />
                  <Route path={routes.ledger} element={<Ledger />} />
                  <Route path={routes.settingsEdit} element={<SettingsEdit />} />
                  <Route path={routes.chatMessages} element={<ChatMessages />} />
                  <Route path={routes.translations} element={<Translations />} />
                  <Route path={routes.blueprints} element={<Blueprints />} />
                  <Route path={routes.blueprintCreate} element={<BlueprintCreate />} />
                  <Route path={routes.blueprintEdit} element={<BlueprintEdit />} />
                  <Route path={routes.statistics} element={<Statistics />} />
                  <Route path={routes.homeBanners} element={<HomeBanners />} />
                  <Route path={routes.homeBannersCreate} element={<HomeBannerCreate />} />
                  <Route path={routes.homeBannersEdit} element={<HomeBannerEdit />} />
                  <Route path={routes.campaigns} element={<Campaigns />} />
                  <Route path={routes.campaignCreate} element={<CampaignsCreate />} />
                  <Route path={routes.campaignEdit} element={<CampaignsEdit />} />
                  <Route path={routes.badges} element={<Badges />} />
                  <Route path={routes.badgeCreate} element={<BadgeCreate />} />
                  <Route path={routes.badgeEdit} element={<BadgeEdit />} />
                  <Route path={routes.levels} element={<Levels />} />
                  <Route path={routes.levelCreate} element={<LevelCreate />} />
                  <Route path={routes.levelEdit} element={<LevelEdit />} />
                  <Route path={routes.mccDiscounts} element={<MCCDiscounts />} />
                  <Route path={routes.mccDiscountCreate} element={<MCCDiscountCreate />} />
                  <Route path={routes.mccDiscountEdit} element={<MCCDiscountEdit />} />
                  <Route path={routes.landDiscounts} element={<LandDiscounts />} />
                  <Route path={routes.landDiscountCreate} element={<LandDiscountCreate />} />
                  <Route path={routes.landDiscountEdit} element={<LandDiscountEdit />} />
                  <Route path={routes.colonistParts} element={<ColonistParts />} />
                  <Route path={routes.colonistPartCreate} element={<ColonistPartCreate />} />
                  <Route path={routes.colonistPartEdit} element={<ColonistPartEdit />} />
                  <Route path={routes.blueprintMysteryBox} element={<BlueprintMysteryBox />} />
                  <Route path={routes.affiliate} element={<Affiliate />} />
                  <Route path={routes.affiliateUser} element={<AffiliateUserEdit />} />
                  <Route path={routes.jumpTask} element={<JumpTask />} />
                  <Route path={routes.craftingRecipe} element={<CraftingRecipes />} />
                  <Route path={routes.craftingRecipeCreate} element={<CraftingRecipeCreate />} />
                  <Route path={routes.craftingRecipeEdit} element={<CraftingRecipeEdit />} />
                  <Route path={routes.resources} element={<Resources />} />
                  <Route path={routes.resourceCreate} element={<ResourceCreate />} />
                  <Route path={routes.resourceEdit} element={<ResourceEdit />} />
                  <Route path={routes.marsBattleBlocks} element={<MarsBattleBlocks />} />
                  <Route path={routes.marsBattleBlockCreate} element={<MarsBattleBlockCreate />} />
                  <Route path={routes.marsBattleBlockEdit} element={<MarsBattleBlockEdit />} />
                  <Route path="*" element={<Navigate to={routes.items} />} />
                </Routes>
              )}
            </Layout>
          </Suspense>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: RootState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  languageLoading: state.language.languageLoading,
  language: state.language.language,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onLanguageInit: (locale: string) => dispatch(languageService.fetchLanguage(locale)),
  onResourceInit: () => dispatch(fetchAvailableResources()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);

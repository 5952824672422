import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HttpError } from '../../config/Axios/axios-instance';
import { AffiliateUser, AffiliateUserStats } from '../../domain/Affiliate';
import { fetchAffiliateUserList, fetchAffiliateUserStats } from './actions';

interface AffiliateStateType {
  users: AffiliateUser[];
  usersLoading: boolean;
  usersError: HttpError;
  userStats: AffiliateUserStats | null;
  userStatsLoading: boolean;
  userStatsError: HttpError;
}

const initialState: AffiliateStateType = {
  users: [],
  usersLoading: false,
  usersError: null,
  userStats: null,
  userStatsLoading: false,
  userStatsError: null,
};

const affiliateSlice = createSlice({
  name: 'affiliate',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAffiliateUserList.fulfilled.type]: (state, action: PayloadAction<AffiliateUser[]>) => {
      state.users = action.payload;
      state.usersLoading = false;
      state.usersError = null;
    },
    [fetchAffiliateUserList.pending.type]: (state) => {
      state.usersLoading = true;
      state.userStats = null;
      state.userStatsLoading = false;
      state.userStatsError = null;
    },
    [fetchAffiliateUserList.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.usersLoading = false;
      state.usersError = action.payload;
    },
    [fetchAffiliateUserStats.fulfilled.type]: (state, action: PayloadAction<AffiliateUserStats>) => {
      state.userStats = action.payload;
      state.userStatsLoading = false;
      state.userStatsError = null;
    },
    [fetchAffiliateUserStats.pending.type]: (state) => {
      state.userStatsLoading = true;
    },
    [fetchAffiliateUserStats.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.userStatsLoading = false;
      state.userStatsError = action.payload;
    },
  },
});

export const affiliateActions = affiliateSlice.actions;

const affiliateReducer = affiliateSlice.reducer;
export default affiliateReducer;

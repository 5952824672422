import { HttpError } from '../../config/Axios/axios-instance';
import * as actionTypes from './actionTypes';
import { StatisticsActionTypes } from './actionTypes';
import { Statistics } from '../../domain/Statistics';

export type StatisticsStateType = {
  statistics: Statistics | null;
  statisticsLoading: boolean;
  statisticsError: HttpError;
};

export type StatisticsActionType = StatisticsStateType & {
  type: StatisticsActionTypes;
};

export const initialState: StatisticsStateType = {
  statistics: null,
  statisticsLoading: false,
  statisticsError: null,
};

const fetchStatisticsStart = (state: StatisticsStateType): StatisticsStateType => ({
  ...state,
  statisticsLoading: true,
});

const fetchStatisticsSuccess = (state: StatisticsStateType, action: StatisticsActionType): StatisticsStateType => ({
  ...state,
  statistics: action.statistics,
  statisticsLoading: false,
  statisticsError: null,
});

const fetchStatisticsFail = (state: StatisticsStateType, action: StatisticsActionType): StatisticsStateType => ({
  ...state,
  statisticsError: action.statisticsError,
  statisticsLoading: false,
});

const reducer = (state = initialState, action: StatisticsActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_STATISTICS_START:
      return fetchStatisticsStart(state);
    case actionTypes.FETCH_STATISTICS_SUCCESS:
      return fetchStatisticsSuccess(state, action);
    case actionTypes.FETCH_STATISTICS_FAIL:
      return fetchStatisticsFail(state, action);
    default:
      return state;
  }
};

export default reducer;

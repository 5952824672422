import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HttpError } from '../../config/Axios/axios-instance';
import { fetchMarsBattleBlocks, fetchMarsBattleBlock, createMarsBattleBlock, updateMarsBattleBlock } from './actions';
import { MarsBattleBlock } from '../../domain/MarsBattleBlock';

interface MarsBattleBlockStateType {
  marsBattleBlockList: MarsBattleBlock[];
  marsBattleBlockListLoading: boolean;
  marsBattleBlockListError: HttpError;
  marsBattleBlock: MarsBattleBlock | null;
  marsBattleBlockLoading: boolean;
  marsBattleBlockError: HttpError;
  marsBattleBlockUpdateLoading: boolean;
  marsBattleBlockUpdateError: HttpError;
  marsBattleBlockCreateLoading: boolean;
  marsBattleBlockCreateError: HttpError;
}

const initialState: MarsBattleBlockStateType = {
  marsBattleBlockList: [],
  marsBattleBlockListLoading: false,
  marsBattleBlockListError: null,
  marsBattleBlock: null,
  marsBattleBlockLoading: false,
  marsBattleBlockError: null,
  marsBattleBlockUpdateLoading: false,
  marsBattleBlockUpdateError: null,
  marsBattleBlockCreateLoading: false,
  marsBattleBlockCreateError: null,
};

const marsBattleBlockSlice = createSlice({
  name: 'marsBattleBlock',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMarsBattleBlocks.fulfilled.type, (state, action: PayloadAction<MarsBattleBlock[]>) => {
      state.marsBattleBlockList = action.payload;
      state.marsBattleBlockListLoading = false;
      state.marsBattleBlockListError = null;
    });
    builder.addCase(fetchMarsBattleBlocks.pending.type, (state) => {
      state.marsBattleBlockListLoading = true;
      state.marsBattleBlockCreateError = null;
      state.marsBattleBlockUpdateError = null;
      state.marsBattleBlockCreateLoading = false;
      state.marsBattleBlockUpdateLoading = false;
    });
    builder.addCase(fetchMarsBattleBlocks.rejected.type, (state, action: PayloadAction<HttpError>) => {
      state.marsBattleBlockListLoading = false;
      state.marsBattleBlockListError = action.payload;
    });

    builder.addCase(fetchMarsBattleBlock.fulfilled.type, (state, action: PayloadAction<MarsBattleBlock>) => {
      state.marsBattleBlock = action.payload;
      state.marsBattleBlockLoading = false;
      state.marsBattleBlockError = null;
    });
    builder.addCase(fetchMarsBattleBlock.pending.type, (state) => {
      state.marsBattleBlockLoading = true;
    });
    builder.addCase(fetchMarsBattleBlock.rejected.type, (state, action: PayloadAction<HttpError>) => {
      state.marsBattleBlockLoading = false;
      state.marsBattleBlockError = action.payload;
    });

    builder.addCase(createMarsBattleBlock.fulfilled.type, (state) => {
      state.marsBattleBlockCreateLoading = false;
      state.marsBattleBlockCreateError = null;
    });
    builder.addCase(createMarsBattleBlock.pending.type, (state) => {
      state.marsBattleBlockCreateError = null;
      state.marsBattleBlockCreateLoading = true;
    });
    builder.addCase(createMarsBattleBlock.rejected.type, (state, action: PayloadAction<HttpError>) => {
      state.marsBattleBlockCreateLoading = false;
      state.marsBattleBlockCreateError = action.payload;
    });

    builder.addCase(updateMarsBattleBlock.fulfilled.type, (state) => {
      state.marsBattleBlockUpdateLoading = false;
      state.marsBattleBlockUpdateError = null;
    });
    builder.addCase(updateMarsBattleBlock.pending.type, (state) => {
      state.marsBattleBlockUpdateError = null;
      state.marsBattleBlockUpdateLoading = true;
    });
    builder.addCase(updateMarsBattleBlock.rejected.type, (state, action: PayloadAction<HttpError>) => {
      state.marsBattleBlockUpdateLoading = false;
      state.marsBattleBlockUpdateError = action.payload;
    });
  },
});

export const marsBattleBlockActions = marsBattleBlockSlice.actions;

const marsBattleBlockReducer = marsBattleBlockSlice.reducer;
export default marsBattleBlockReducer;

import React from 'react';
import Router from './config/Router/Router';
import StoreProvider from './store/StoreProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';

const App = () => (
  <GoogleOAuthProvider clientId="186363523401-tas3vl3suq1c7a94lcpm95mj5hjkf4mr.apps.googleusercontent.com">
    <StoreProvider>
      <Router />
      <ToastContainer />
    </StoreProvider>
  </GoogleOAuthProvider>
);

export default App;

import { HttpError } from '../../config/Axios/axios-instance';
import * as actionTypes from './actionTypes';
import { BlueprintMysteryBoxActionTypes } from './actionTypes';
import { BlueprintMysteryBoxPriceItem, BlueprintMysteryBoxReward } from '../../domain/BlueprintMysteryBox';

export type BlueprintMysteryBoxStateType = {
  price: BlueprintMysteryBoxPriceItem[];
  priceLoading: boolean;
  priceError: HttpError;
  rewards: BlueprintMysteryBoxReward[];
  rewardsLoading: boolean;
  rewardsError: HttpError;
  updatePriceLoading: boolean;
  updateRewardsLoading: boolean;
};

export type BlueprintMysteryBoxActionType = BlueprintMysteryBoxStateType & {
  type: BlueprintMysteryBoxActionTypes;
};

export const initialState: BlueprintMysteryBoxStateType = {
  price: [],
  priceLoading: false,
  priceError: null,
  rewards: [],
  rewardsLoading: false,
  rewardsError: null,
  updatePriceLoading: false,
  updateRewardsLoading: false,
};

const fetchBlueprintMysteryBoxPriceStart = (state: BlueprintMysteryBoxStateType): BlueprintMysteryBoxStateType => ({
  ...state,
  priceLoading: true,
});

const fetchBlueprintMysteryBoxPriceSuccess = (
  state: BlueprintMysteryBoxStateType,
  action: BlueprintMysteryBoxActionType,
): BlueprintMysteryBoxStateType => ({
  ...state,
  price: action.price,
  priceLoading: false,
  priceError: null,
});

const fetchBlueprintMysteryBoxPriceFail = (
  state: BlueprintMysteryBoxStateType,
  action: BlueprintMysteryBoxActionType,
): BlueprintMysteryBoxStateType => ({
  ...state,
  priceError: action.priceError,
  priceLoading: false,
});

const fetchBlueprintMysteryBoxRewardsStart = (state: BlueprintMysteryBoxStateType): BlueprintMysteryBoxStateType => ({
  ...state,
  rewardsLoading: true,
});

const fetchBlueprintMysteryBoxRewardsSuccess = (
  state: BlueprintMysteryBoxStateType,
  action: BlueprintMysteryBoxActionType,
): BlueprintMysteryBoxStateType => ({
  ...state,
  rewards: action.rewards,
  rewardsLoading: false,
  rewardsError: null,
});

const fetchBlueprintMysteryBoxRewardsFail = (
  state: BlueprintMysteryBoxStateType,
  action: BlueprintMysteryBoxActionType,
): BlueprintMysteryBoxStateType => ({
  ...state,
  rewardsError: action.rewardsError,
  rewardsLoading: false,
});

const updateBlueprintMysteryBoxPriceStart = (state: BlueprintMysteryBoxStateType): BlueprintMysteryBoxStateType => ({
  ...state,
  updatePriceLoading: true,
});

const updateBlueprintMysteryBoxPriceSuccess = (
  state: BlueprintMysteryBoxStateType,
  action: BlueprintMysteryBoxActionType,
): BlueprintMysteryBoxStateType => ({
  ...state,
  price: action.price,
  updatePriceLoading: false,
});

const updateBlueprintMysteryBoxPriceFail = (
  state: BlueprintMysteryBoxStateType,
  action: BlueprintMysteryBoxActionType,
): BlueprintMysteryBoxStateType => ({
  ...state,
  updatePriceLoading: false,
});

const updateBlueprintMysteryBoxRewardsStart = (state: BlueprintMysteryBoxStateType): BlueprintMysteryBoxStateType => ({
  ...state,
  updateRewardsLoading: true,
});

const updateBlueprintMysteryBoxRewardsSuccess = (
  state: BlueprintMysteryBoxStateType,
  action: BlueprintMysteryBoxActionType,
): BlueprintMysteryBoxStateType => ({
  ...state,
  rewards: action.rewards,
  updateRewardsLoading: false,
});

const updateBlueprintMysteryBoxRewardsFail = (
  state: BlueprintMysteryBoxStateType,
  action: BlueprintMysteryBoxActionType,
): BlueprintMysteryBoxStateType => ({
  ...state,
  updateRewardsLoading: false,
});

const reducer = (state = initialState, action: BlueprintMysteryBoxActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_BLUEPRINT_MYSTERY_BOX_PRICE_START:
      return fetchBlueprintMysteryBoxPriceStart(state);
    case actionTypes.FETCH_BLUEPRINT_MYSTERY_BOX_PRICE_SUCCESS:
      return fetchBlueprintMysteryBoxPriceSuccess(state, action);
    case actionTypes.FETCH_BLUEPRINT_MYSTERY_BOX_PRICE_FAIL:
      return fetchBlueprintMysteryBoxPriceFail(state, action);
    case actionTypes.FETCH_BLUEPRINT_MYSTERY_BOX_REWARDS_START:
      return fetchBlueprintMysteryBoxRewardsStart(state);
    case actionTypes.FETCH_BLUEPRINT_MYSTERY_BOX_REWARDS_SUCCESS:
      return fetchBlueprintMysteryBoxRewardsSuccess(state, action);
    case actionTypes.FETCH_BLUEPRINT_MYSTERY_BOX_REWARDS_FAIL:
      return fetchBlueprintMysteryBoxRewardsFail(state, action);
    case actionTypes.UPDATE_BLUEPRINT_MYSTERY_BOX_PRICE_START:
      return updateBlueprintMysteryBoxPriceStart(state);
    case actionTypes.UPDATE_BLUEPRINT_MYSTERY_BOX_PRICE_SUCCESS:
      return updateBlueprintMysteryBoxPriceSuccess(state, action);
    case actionTypes.UPDATE_BLUEPRINT_MYSTERY_BOX_PRICE_FAIL:
      return updateBlueprintMysteryBoxPriceFail(state, action);
    case actionTypes.UPDATE_BLUEPRINT_MYSTERY_BOX_REWARDS_START:
      return updateBlueprintMysteryBoxRewardsStart(state);
    case actionTypes.UPDATE_BLUEPRINT_MYSTERY_BOX_REWARDS_SUCCESS:
      return updateBlueprintMysteryBoxRewardsSuccess(state, action);
    case actionTypes.UPDATE_BLUEPRINT_MYSTERY_BOX_REWARDS_FAIL:
      return updateBlueprintMysteryBoxRewardsFail(state, action);
    default:
      return state;
  }
};

export default reducer;

export interface Badge {
  id: number;
  key: string;
  image: BadgeImage;
  inactiveImage: BadgeImage;
  title: string;
  description: string;
  category: BadgeCategory;
  enabled: boolean;
  experience?: number;
  createdAt: Date;
}

export interface BadgesList {
  result: Badge[];
  total: number;
}

export interface BadgeImage {
  id: number;
  location: string;
}

export enum BadgeCategory {
  // eslint-disable-next-line no-unused-vars
  MCC = 'mcc',
  // eslint-disable-next-line no-unused-vars
  Game = 'game',
}

export const BadgeKeys = ['Landlord', 'Fish', 'Shark', 'Whale', 'Megalodon', 'Explorer', 'Miner', 'Builder', 'Family'];

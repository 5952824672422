import { HttpError } from '../../config/Axios/axios-instance';
import { UsersList } from '../../domain/User';
import * as actionTypes from './actionTypes';
import { UserActionTypes } from './actionTypes';

export type UserStateType = {
  users: UsersList | null;
  usersLoading: boolean;
  usersError: HttpError;
};

export type UserActionType = UserStateType & {
  type: UserActionTypes;
};

export const initialState: UserStateType = {
  users: null,
  usersLoading: false,
  usersError: null,
};

const fetchUsersStart = (state: UserStateType): UserStateType => ({
  ...state,
  usersLoading: true,
});

const fetchUsersSuccess = (state: UserStateType, action: UserActionType): UserStateType => ({
  ...state,
  users: action.users,
  usersLoading: false,
  usersError: null,
});

const fetchUsersFail = (state: UserStateType, action: UserActionType): UserStateType => ({
  ...state,
  usersError: action.usersError,
  usersLoading: false,
});

const reducer = (state = initialState, action: UserActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_USERS_START:
      return fetchUsersStart(state);
    case actionTypes.FETCH_USERS_SUCCESS:
      return fetchUsersSuccess(state, action);
    case actionTypes.FETCH_USERS_FAIL:
      return fetchUsersFail(state, action);
    default:
      return state;
  }
};

export default reducer;

import { HttpError } from '../../config/Axios/axios-instance';
import { Badge, BadgeKeys, BadgesList } from '../../domain/Badge';
import * as actionTypes from './actionTypes';
import { BadgeActionTypes } from './actionTypes';

export type BadgeStateType = {
  badges: BadgesList | null;
  badgesLoading: boolean;
  badgesError: HttpError;
  badge: Badge | null;
  badgeLoading: boolean;
  badgeError: HttpError;
  badgeUpdateLoading: boolean;
  badgeUpdateError: HttpError;
  badgeUpdateSuccess: boolean;
  badgeCreateLoading: boolean;
  badgeCreateError: HttpError;
  badgeCreateSuccess: boolean;
  createdBadge: Badge | null;
  availableBadgeKeys: string[];
  availableBadgeKeysLoading: boolean;
  availableBadgeKeysError: HttpError;
  totalExperience: number;
  totalExperienceLoading: boolean;
  totalExperienceError: HttpError;
};

export type BadgeActionType = BadgeStateType & {
  type: BadgeActionTypes;
};

export const initialState: BadgeStateType = {
  badges: null,
  badgesLoading: false,
  badgesError: null,
  badge: null,
  badgeLoading: false,
  badgeError: null,
  badgeUpdateLoading: false,
  badgeUpdateError: null,
  badgeUpdateSuccess: false,
  badgeCreateLoading: false,
  badgeCreateError: null,
  badgeCreateSuccess: false,
  createdBadge: null,
  availableBadgeKeys: BadgeKeys,
  availableBadgeKeysLoading: false,
  availableBadgeKeysError: null,
  totalExperience: 0,
  totalExperienceLoading: false,
  totalExperienceError: null,
};

const fetchTotalExperienceStart = (state: BadgeStateType): BadgeStateType => ({
  ...state,
  totalExperienceLoading: true,
});

const fetchTotalExperienceSuccess = (state: BadgeStateType, action: BadgeActionType): BadgeStateType => ({
  ...state,
  totalExperience: action.totalExperience,
  totalExperienceLoading: false,
  totalExperienceError: null,
});

const fetchTotalExperienceFail = (state: BadgeStateType, action: BadgeActionType): BadgeStateType => ({
  ...state,
  totalExperienceError: action.totalExperienceError,
  totalExperienceLoading: false,
});

const fetchBadgesStart = (state: BadgeStateType): BadgeStateType => ({
  ...state,
  badgesLoading: true,
  badge: null,
  badgeCreateSuccess: false,
  badgeUpdateSuccess: false,
});

const fetchBadgesSuccess = (state: BadgeStateType, action: BadgeActionType): BadgeStateType => ({
  ...state,
  badges: action.badges,
  badgesLoading: false,
  badgesError: null,
});

const fetchBadgesFail = (state: BadgeStateType, action: BadgeActionType): BadgeStateType => ({
  ...state,
  badgesError: action.badgesError,
  badgesLoading: false,
});

const fetchBadgeStart = (state: BadgeStateType): BadgeStateType => ({
  ...state,
  badgeLoading: true,
  badgeCreateSuccess: false,
  badgeUpdateSuccess: false,
  badgeUpdateError: null,
  badgeCreateError: null,
});

const fetchBadgeSuccess = (state: BadgeStateType, action: BadgeActionType): BadgeStateType => ({
  ...state,
  badge: action.badge,
  badgeLoading: false,
  badgeError: null,
});

const fetchBadgeFail = (state: BadgeStateType, action: BadgeActionType): BadgeStateType => ({
  ...state,
  badgeError: action.badgeError,
  badgeLoading: false,
});

const updateBadgeStart = (state: BadgeStateType): BadgeStateType => ({
  ...state,
  badgeUpdateLoading: true,
});

const updateBadgeSuccess = (state: BadgeStateType): BadgeStateType => ({
  ...state,
  badgeUpdateLoading: false,
  badgeUpdateError: null,
  badgeUpdateSuccess: true,
});

const updateBadgeFail = (state: BadgeStateType, action: BadgeActionType): BadgeStateType => ({
  ...state,
  badgeUpdateLoading: false,
  badgeUpdateError: action.badgeUpdateError,
});

const createBadgeStart = (state: BadgeStateType): BadgeStateType => ({
  ...state,
  badgeCreateLoading: true,
});

const createBadgeSuccess = (state: BadgeStateType, action: BadgeActionType): BadgeStateType => ({
  ...state,
  badgeCreateLoading: false,
  badgeCreateError: null,
  badgeCreateSuccess: true,
  createdBadge: action.createdBadge,
});

const createBadgeFail = (state: BadgeStateType, action: BadgeActionType): BadgeStateType => ({
  ...state,
  badgeCreateLoading: false,
  badgeCreateError: action.badgeCreateError,
});

const fetchBadgeKeysStart = (state: BadgeStateType): BadgeStateType => ({
  ...state,
  availableBadgeKeysLoading: true,
});

const fetchBadgeKeysSuccess = (state: BadgeStateType, action: BadgeActionType): BadgeStateType => ({
  ...state,
  availableBadgeKeysLoading: false,
  availableBadgeKeysError: null,
  availableBadgeKeys: action.availableBadgeKeys,
});

const fetchBadgeKeysFail = (state: BadgeStateType, action: BadgeActionType): BadgeStateType => ({
  ...state,
  availableBadgeKeysLoading: false,
  availableBadgeKeysError: action.availableBadgeKeysError,
});

const reducer = (state = initialState, action: BadgeActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_BADGES_START:
      return fetchBadgesStart(state);
    case actionTypes.FETCH_BADGES_SUCCESS:
      return fetchBadgesSuccess(state, action);
    case actionTypes.FETCH_BADGES_FAIL:
      return fetchBadgesFail(state, action);
    case actionTypes.FETCH_BADGE_START:
      return fetchBadgeStart(state);
    case actionTypes.FETCH_BADGE_SUCCESS:
      return fetchBadgeSuccess(state, action);
    case actionTypes.FETCH_BADGE_FAIL:
      return fetchBadgeFail(state, action);
    case actionTypes.UPDATE_BADGE_START:
      return updateBadgeStart(state);
    case actionTypes.UPDATE_BADGE_SUCCESS:
      return updateBadgeSuccess(state);
    case actionTypes.UPDATE_BADGE_FAIL:
      return updateBadgeFail(state, action);
    case actionTypes.CREATE_BADGE_START:
      return createBadgeStart(state);
    case actionTypes.CREATE_BADGE_SUCCESS:
      return createBadgeSuccess(state, action);
    case actionTypes.CREATE_BADGE_FAIL:
      return createBadgeFail(state, action);
    case actionTypes.FETCH_BADGE_KEYS_START:
      return fetchBadgeKeysStart(state);
    case actionTypes.FETCH_BADGE_KEYS_SUCCESS:
      return fetchBadgeKeysSuccess(state, action);
    case actionTypes.FETCH_BADGE_KEYS_FAIL:
      return fetchBadgeKeysFail(state, action);
    case actionTypes.FETCH_TOTAL_EXPERIENCE_START:
      return fetchTotalExperienceStart(state);
    case actionTypes.FETCH_TOTAL_EXPERIENCE_SUCCESS:
      return fetchTotalExperienceSuccess(state, action);
    case actionTypes.FETCH_TOTAL_EXPERIENCE_FAIL:
      return fetchTotalExperienceFail(state, action);
    default:
      return state;
  }
};

export default reducer;

import React, { useState } from 'react';
import styles from './Navigation.module.scss';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import {
  faBars,
  faChartLine,
  faUsers,
  faSignOutAlt,
  faTimes,
  faMoneyBill,
  faComments,
  faTextWidth,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as authService from '../../store/auth/service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { routes } from '../../config/Router/routes';
import cx from 'classnames';

export type Props = {
  onLogout: () => void;
};

const MOBILE_BREAK_POINT = 1200;

export const Navigation = ({ onLogout }: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { width } = useWindowSize();
  const intl = useIntl();

  const SIDEBAR_ITEMS = [
    {
      label: translate(intl, 'SIDEBAR.PRODUCTS', 'Products'),
      to: routes.items,
      icon: faChartLine,
      roles: ['ADMIN'],
    },
    {
      label: translate(intl, 'SIDEBAR.USERS', 'Users'),
      to: routes.users,
      icon: faUsers,
      roles: ['ADMIN'],
    },
    {
      label: translate(intl, 'SIDEBAR.LEDGER', 'Ledger'),
      to: routes.ledger,
      icon: faMoneyBill,
      roles: ['ADMIN'],
    },
    {
      label: translate(intl, 'SIDEBAR.CHAT_MESSAGES', 'Chat messages'),
      to: routes.chatMessages,
      icon: faComments,
      roles: ['ADMIN'],
    },
    {
      label: translate(intl, 'SIDEBAR.TRANSLATIONS', 'Translations'),
      to: routes.chatMessages,
      icon: faTextWidth,
      roles: ['ADMIN'],
    },
  ];

  const role = localStorage.getItem('role');

  return (
    <>
      <header className={styles.navigationContainer}>
        <div className={styles.iconItems}>
          {width && width < MOBILE_BREAK_POINT && (
            <IconButton onClick={() => setIsMobileMenuOpen((prev) => !prev)}>
              <FontAwesomeIcon icon={(isMobileMenuOpen ? faTimes : faBars) as IconProp} fixedWidth size="sm" />
            </IconButton>
          )}
        </div>
        <div className={styles.iconItems}>
          <IconButton onClick={() => onLogout()}>
            <FontAwesomeIcon icon={faSignOutAlt as IconProp} fixedWidth size="sm" />
          </IconButton>
        </div>
      </header>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {SIDEBAR_ITEMS.filter((navItem) => role && navItem.roles.includes(role)).map((navItem) => (
            <NavLink
              key={navItem.to + navItem.label}
              to={navItem.to}
              className={cx(styles.navigationItem, {
                [styles.activeNavigationItem]: location.pathname.includes(navItem.to),
              })}
            >
              <FontAwesomeIcon icon={navItem.icon as IconProp} fixedWidth />
              <div className={styles.navigationText}>{navItem.label}</div>
            </NavLink>
          ))}
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onLogout: () => dispatch(authService.logoutUser()),
});

export default connect(null, mapDispatchToProps)(Navigation);

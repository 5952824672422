import * as actionTypes from './actionTypes';
import { AuthActionTypes } from './actionTypes';

export type AuthStateType = {
  isAuthenticated: boolean;
  isInitCompleted: boolean;
  loginGoogleLoading: boolean;
  loginGoogleError: string | null;
};

export type AuthActionType = AuthStateType & {
  type: AuthActionTypes;
};

export const initialState: AuthStateType = {
  isAuthenticated: false,
  isInitCompleted: false,
  loginGoogleLoading: false,
  loginGoogleError: null,
};

const loginGoogleStart = (state: AuthStateType): AuthStateType => ({
  ...state,
  loginGoogleLoading: true,
});

const loginGoogleSuccess = (state: AuthStateType): AuthStateType => ({
  ...state,
  isAuthenticated: true,
  loginGoogleError: null,
  loginGoogleLoading: false,
  isInitCompleted: true,
});

const loginGoogleFail = (state: AuthStateType, action: AuthActionType): AuthStateType => ({
  ...state,
  loginGoogleError: action.loginGoogleError,
  loginGoogleLoading: false,
  isInitCompleted: true,
});

const logout = (): AuthStateType => ({
  isAuthenticated: false,
  loginGoogleLoading: false,
  loginGoogleError: null,
  isInitCompleted: true,
});

const reducer = (state = initialState, action: AuthActionType) => {
  switch (action.type) {
    case actionTypes.LOGIN_GOOGLE_START:
      return loginGoogleStart(state);
    case actionTypes.LOGIN_GOOGLE_SUCCESS:
      return loginGoogleSuccess(state);
    case actionTypes.LOGIN_GOOGLE_FAIL:
      return loginGoogleFail(state, action);
    case actionTypes.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default reducer;

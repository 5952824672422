import { HttpError } from '../../config/Axios/axios-instance';
import { HomeBanner, HomeBannerList } from '../../domain/HomeBanner';
import * as actionTypes from './actionTypes';
import { HomeBannerActionTypes } from './actionTypes';

export type HomeBannerStateType = {
  homeBanners: HomeBannerList | null;
  homeBannersLoading: boolean;
  homeBannersError: HttpError;
  homeBanner: HomeBanner | null;
  homeBannerLoading: boolean;
  homeBannerError: HttpError;
  homeBannerUpdateLoading: boolean;
  homeBannerUpdateError: HttpError;
  homeBannerUpdateSuccess: boolean;
  homeBannerCreateLoading: boolean;
  homeBannerCreateError: HttpError;
  homeBannerCreateSuccess: boolean;
  createdHomeBanner: HomeBanner | null;
};

export type HomeBannerActionType = HomeBannerStateType & {
  type: HomeBannerActionTypes;
};

export const initialState: HomeBannerStateType = {
  homeBanners: null,
  homeBannersLoading: false,
  homeBannersError: null,
  homeBanner: null,
  homeBannerLoading: false,
  homeBannerError: null,
  homeBannerUpdateLoading: false,
  homeBannerUpdateError: null,
  homeBannerUpdateSuccess: false,
  homeBannerCreateLoading: false,
  homeBannerCreateError: null,
  homeBannerCreateSuccess: false,
  createdHomeBanner: null,
};

const fetchHomeBannersStart = (state: HomeBannerStateType): HomeBannerStateType => ({
  ...state,
  homeBannersLoading: true,
  homeBanner: null,
  homeBannerCreateSuccess: false,
  homeBannerUpdateSuccess: false,
});

const fetchHomeBannersSuccess = (state: HomeBannerStateType, action: HomeBannerActionType): HomeBannerStateType => ({
  ...state,
  homeBanners: action.homeBanners,
  homeBannersLoading: false,
  homeBannersError: null,
});

const fetchHomeBannersFail = (state: HomeBannerStateType, action: HomeBannerActionType): HomeBannerStateType => ({
  ...state,
  homeBannersError: action.homeBannersError,
  homeBannersLoading: false,
});

const fetchHomeBannerStart = (state: HomeBannerStateType): HomeBannerStateType => ({
  ...state,
  homeBannerLoading: true,
  homeBannerCreateSuccess: false,
  homeBannerUpdateSuccess: false,
  homeBannerUpdateError: null,
  homeBannerCreateError: null,
});

const fetchHomeBannerSuccess = (state: HomeBannerStateType, action: HomeBannerActionType): HomeBannerStateType => ({
  ...state,
  homeBanner: action.homeBanner,
  homeBannerLoading: false,
  homeBannerError: null,
});

const fetchHomeBannerFail = (state: HomeBannerStateType, action: HomeBannerActionType): HomeBannerStateType => ({
  ...state,
  homeBannerError: action.homeBannerError,
  homeBannerLoading: false,
});

const updateHomeBannerStart = (state: HomeBannerStateType): HomeBannerStateType => ({
  ...state,
  homeBannerUpdateLoading: true,
});

const updateHomeBannerSuccess = (state: HomeBannerStateType): HomeBannerStateType => ({
  ...state,
  homeBannerUpdateLoading: false,
  homeBannerUpdateError: null,
  homeBannerUpdateSuccess: true,
});

const updateHomeBannerFail = (state: HomeBannerStateType, action: HomeBannerActionType): HomeBannerStateType => ({
  ...state,
  homeBannerUpdateLoading: false,
  homeBannerUpdateError: action.homeBannerUpdateError,
});

const createHomeBannerStart = (state: HomeBannerStateType): HomeBannerStateType => ({
  ...state,
  homeBannerCreateLoading: true,
});

const createHomeBannerSuccess = (state: HomeBannerStateType, action: HomeBannerActionType): HomeBannerStateType => ({
  ...state,
  homeBannerCreateLoading: false,
  homeBannerCreateError: null,
  homeBannerCreateSuccess: true,
  createdHomeBanner: action.createdHomeBanner,
});

const createHomeBannerFail = (state: HomeBannerStateType, action: HomeBannerActionType): HomeBannerStateType => ({
  ...state,
  homeBannerCreateLoading: false,
  homeBannerCreateError: action.homeBannerCreateError,
});

const reducer = (state = initialState, action: HomeBannerActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_HOME_BANNERS_START:
      return fetchHomeBannersStart(state);
    case actionTypes.FETCH_HOME_BANNERS_SUCCESS:
      return fetchHomeBannersSuccess(state, action);
    case actionTypes.FETCH_HOME_BANNERS_FAIL:
      return fetchHomeBannersFail(state, action);
    case actionTypes.FETCH_HOME_BANNER_START:
      return fetchHomeBannerStart(state);
    case actionTypes.FETCH_HOME_BANNER_SUCCESS:
      return fetchHomeBannerSuccess(state, action);
    case actionTypes.FETCH_HOME_BANNER_FAIL:
      return fetchHomeBannerFail(state, action);
    case actionTypes.UPDATE_HOME_BANNER_START:
      return updateHomeBannerStart(state);
    case actionTypes.UPDATE_HOME_BANNER_SUCCESS:
      return updateHomeBannerSuccess(state);
    case actionTypes.UPDATE_HOME_BANNER_FAIL:
      return updateHomeBannerFail(state, action);
    case actionTypes.CREATE_HOME_BANNER_START:
      return createHomeBannerStart(state);
    case actionTypes.CREATE_HOME_BANNER_SUCCESS:
      return createHomeBannerSuccess(state, action);
    case actionTypes.CREATE_HOME_BANNER_FAIL:
      return createHomeBannerFail(state, action);
    default:
      return state;
  }
};

export default reducer;

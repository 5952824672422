import axios from '../../config/Axios/axios-instance';
import { loginGoogleFail, loginGoogleStart, loginGoogleSuccess, logout } from './actions';
import { Dispatch } from 'redux';

const API_AUTH_URL = '/auth';

export type LoginGoogleRequest = {
  email: string;
  firstName?: string;
  lastName?: string;
  imageUrl?: string;
  token: string;
};

export const loginWithGoogle = (inputs: LoginGoogleRequest) => (dispatch: Dispatch) => {
  dispatch(loginGoogleStart());
  return axios
    .post(`${API_AUTH_URL}/google`, {
      ...inputs,
    })
    .then((response) => {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      localStorage.setItem('role', response.data.role);
      dispatch(loginGoogleSuccess());
    })
    .catch((err) => {
      dispatch(loginGoogleFail(err.response?.data?.message));
    });
};

export const authCheckState = () => (dispatch: Dispatch) => {
  const token = localStorage.getItem('token');
  return token ? dispatch(loginGoogleSuccess()) : dispatch(logout());
};

export const logoutUser = () => (dispatch: Dispatch) => {
  const token = localStorage.getItem('token');
  localStorage.removeItem('token');

  dispatch(logout());

  if (!!token) {
    axios.get(`${API_AUTH_URL}/logout`, { headers: { Authorization: `Bearer ${token}` } }).finally(() => {});
  }
};

export const refresh = async () => {
  const refreshToken = localStorage.getItem('refreshToken');

  if (!!refreshToken) {
    localStorage.removeItem('token');
    return axios
      .get(`${API_AUTH_URL}/refresh`, { headers: { Authorization: `Bearer ${refreshToken}` } })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('refreshToken', response.data.refreshToken);
        return response.data.token;
      })
      .catch(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        return undefined;
      });
  } else {
    return undefined;
  }
};

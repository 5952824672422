import { HttpError } from '../../config/Axios/axios-instance';
import { Campaign, CampaignsList } from '../../domain/Campaign';
import * as actionTypes from './actionTypes';
import { CampaignActionTypes } from './actionTypes';

export type CampaignStateType = {
  campaigns: CampaignsList | null;
  campaignsLoading: boolean;
  campaignsError: HttpError;
  campaign: Campaign | null;
  campaignLoading: boolean;
  campaignError: HttpError;
  campaignUpdateLoading: boolean;
  campaignUpdateError: HttpError;
  campaignUpdateSuccess: boolean;
  campaignCreateLoading: boolean;
  campaignCreateError: HttpError;
  campaignCreateSuccess: boolean;
  campaignDeleteLoading: boolean;
  campaignDeleteError: HttpError;
  campaignDeleteSuccess: boolean;
  createdCampaign: Campaign | null;
};

export type CampaignActionType = CampaignStateType & {
  type: CampaignActionTypes;
};

export const initialState: CampaignStateType = {
  campaigns: null,
  campaignsLoading: false,
  campaignsError: null,
  campaign: null,
  campaignLoading: false,
  campaignError: null,
  campaignUpdateLoading: false,
  campaignUpdateError: null,
  campaignUpdateSuccess: false,
  campaignCreateLoading: false,
  campaignCreateError: null,
  campaignCreateSuccess: false,
  campaignDeleteLoading: false,
  campaignDeleteError: null,
  campaignDeleteSuccess: false,
  createdCampaign: null,
};

const fetchCampaignsStart = (state: CampaignStateType): CampaignStateType => ({
  ...state,
  campaignsLoading: true,
  campaign: null,
  campaignCreateSuccess: false,
  campaignUpdateSuccess: false,
});

const fetchCampaignsSuccess = (state: CampaignStateType, action: CampaignActionType): CampaignStateType => ({
  ...state,
  campaigns: action.campaigns,
  campaignsLoading: false,
  campaignsError: null,
});

const fetchCampaignsFail = (state: CampaignStateType, action: CampaignActionType): CampaignStateType => ({
  ...state,
  campaignsError: action.campaignsError,
  campaignsLoading: false,
});

const fetchCampaignStart = (state: CampaignStateType): CampaignStateType => ({
  ...state,
  campaignLoading: true,
  campaignCreateSuccess: false,
  campaignUpdateSuccess: false,
  campaignUpdateError: null,
  campaignCreateError: null,
});

const fetchCampaignSuccess = (state: CampaignStateType, action: CampaignActionType): CampaignStateType => ({
  ...state,
  campaign: action.campaign,
  campaignLoading: false,
  campaignError: null,
});

const fetchCampaignFail = (state: CampaignStateType, action: CampaignActionType): CampaignStateType => ({
  ...state,
  campaignError: action.campaignError,
  campaignLoading: false,
});

const updateCampaignStart = (state: CampaignStateType): CampaignStateType => ({
  ...state,
  campaignUpdateLoading: true,
});

const updateCampaignSuccess = (state: CampaignStateType): CampaignStateType => ({
  ...state,
  campaignUpdateLoading: false,
  campaignUpdateError: null,
  campaignUpdateSuccess: true,
});

const updateCampaignFail = (state: CampaignStateType, action: CampaignActionType): CampaignStateType => ({
  ...state,
  campaignUpdateLoading: false,
  campaignUpdateError: action.campaignUpdateError,
});

const createCampaignStart = (state: CampaignStateType): CampaignStateType => ({
  ...state,
  campaignCreateLoading: true,
});

const createCampaignSuccess = (state: CampaignStateType, action: CampaignActionType): CampaignStateType => ({
  ...state,
  campaignCreateLoading: false,
  campaignCreateError: null,
  campaignCreateSuccess: true,
  createdCampaign: action.createdCampaign,
});

const createCampaignFail = (state: CampaignStateType, action: CampaignActionType): CampaignStateType => ({
  ...state,
  campaignCreateLoading: false,
  campaignCreateError: action.campaignCreateError,
});

const deleteCampaignStart = (state: CampaignStateType): CampaignStateType => ({
  ...state,
  campaignCreateLoading: true,
});

const deleteCampaignSuccess = (state: CampaignStateType, action: CampaignActionType): CampaignStateType => ({
  ...state,
  campaignCreateLoading: false,
  campaignCreateError: null,
  campaignCreateSuccess: true,
  createdCampaign: action.createdCampaign,
});

const deleteCampaignFail = (state: CampaignStateType, action: CampaignActionType): CampaignStateType => ({
  ...state,
  campaignCreateLoading: false,
  campaignCreateError: action.campaignCreateError,
});

const reducer = (state = initialState, action: CampaignActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGNS_START:
      return fetchCampaignsStart(state);
    case actionTypes.FETCH_CAMPAIGNS_SUCCESS:
      return fetchCampaignsSuccess(state, action);
    case actionTypes.FETCH_CAMPAIGNS_FAIL:
      return fetchCampaignsFail(state, action);
    case actionTypes.FETCH_CAMPAIGN_START:
      return fetchCampaignStart(state);
    case actionTypes.FETCH_CAMPAIGN_SUCCESS:
      return fetchCampaignSuccess(state, action);
    case actionTypes.FETCH_CAMPAIGN_FAIL:
      return fetchCampaignFail(state, action);
    case actionTypes.UPDATE_CAMPAIGN_START:
      return updateCampaignStart(state);
    case actionTypes.UPDATE_CAMPAIGN_SUCCESS:
      return updateCampaignSuccess(state);
    case actionTypes.UPDATE_CAMPAIGN_FAIL:
      return updateCampaignFail(state, action);
    case actionTypes.CREATE_CAMPAIGN_START:
      return createCampaignStart(state);
    case actionTypes.CREATE_CAMPAIGN_SUCCESS:
      return createCampaignSuccess(state, action);
    case actionTypes.CREATE_CAMPAIGN_FAIL:
      return createCampaignFail(state, action);
    case actionTypes.DELETE_CAMPAIGN_START:
      return deleteCampaignStart(state);
    case actionTypes.DELETE_CAMPAIGN_SUCCESS:
      return deleteCampaignSuccess(state, action);
    case actionTypes.DELETE_CAMPAIGN_FAIL:
      return deleteCampaignFail(state, action);
    default:
      return state;
  }
};

export default reducer;

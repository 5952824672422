export const FETCH_BADGES_START = 'FETCH_BADGES_START';
export const FETCH_BADGES_SUCCESS = 'FETCH_BADGES_SUCCESS';
export const FETCH_BADGES_FAIL = 'FETCH_BADGES_FAIL';

export const FETCH_BADGE_START = 'FETCH_BADGE_START';
export const FETCH_BADGE_SUCCESS = 'FETCH_BADGE_SUCCESS';
export const FETCH_BADGE_FAIL = 'FETCH_BADGE_FAIL';

export const UPDATE_BADGE_START = 'UPDATE_BADGE_START';
export const UPDATE_BADGE_SUCCESS = 'UPDATE_BADGE_SUCCESS';
export const UPDATE_BADGE_FAIL = 'UPDATE_BADGE_FAIL';

export const CREATE_BADGE_START = 'CREATE_BADGE_START';
export const CREATE_BADGE_SUCCESS = 'CREATE_BADGE_SUCCESS';
export const CREATE_BADGE_FAIL = 'CREATE_BADGE_FAIL';

export const FETCH_BADGE_KEYS_START = 'FETCH_BADGE_KEYS_START';
export const FETCH_BADGE_KEYS_SUCCESS = 'FETCH_BADGE_KEYS_SUCCESS';
export const FETCH_BADGE_KEYS_FAIL = 'FETCH_BADGE_KEYS_FAIL';

export const FETCH_TOTAL_EXPERIENCE_START = 'FETCH_TOTAL_EXPERIENCE_START';
export const FETCH_TOTAL_EXPERIENCE_SUCCESS = 'FETCH_TOTAL_EXPERIENCE_SUCCESS';
export const FETCH_TOTAL_EXPERIENCE_FAIL = 'FETCH_TOTAL_EXPERIENCE_FAIL';

export type BadgeActionTypes =
  | typeof FETCH_BADGES_START
  | typeof FETCH_BADGES_SUCCESS
  | typeof FETCH_BADGES_FAIL
  | typeof FETCH_BADGE_START
  | typeof FETCH_BADGE_SUCCESS
  | typeof FETCH_BADGE_FAIL
  | typeof UPDATE_BADGE_START
  | typeof UPDATE_BADGE_SUCCESS
  | typeof UPDATE_BADGE_FAIL
  | typeof CREATE_BADGE_START
  | typeof CREATE_BADGE_SUCCESS
  | typeof CREATE_BADGE_FAIL
  | typeof FETCH_BADGE_KEYS_START
  | typeof FETCH_BADGE_KEYS_SUCCESS
  | typeof FETCH_BADGE_KEYS_FAIL
  | typeof FETCH_TOTAL_EXPERIENCE_START
  | typeof FETCH_TOTAL_EXPERIENCE_SUCCESS
  | typeof FETCH_TOTAL_EXPERIENCE_FAIL;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../config/Axios/axios-instance';
import { ListParams } from '../../hooks/useList/useList';
import { CraftingRecipe, CraftingRecipeResource, CraftingRecipesList } from '../../domain/CraftingRecipe';
import { AxiosError } from 'axios';

export type CraftingRecipeCreateRequest = {
  name: string;
  description: string;
  craftingTime: number;
  enabled: boolean;
  image: File;
  requiredResources: CraftingRecipeResource[];
  outputResources: CraftingRecipeResource[];
};

export type CraftingRecipeUpdateRequest = {
  id: number;
  name: string;
  description: string;
  craftingTime: number;
  enabled: boolean;
  image?: File;
  imageId?: number;
  requiredResources: CraftingRecipeResource[];
  outputResources: CraftingRecipeResource[];
};

export const fetchCraftingRecipeList = createAsyncThunk(
  'craftingRecipe/list',
  async (params: ListParams | undefined, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<CraftingRecipesList>(`/crafting-recipe/all`, { params });
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data?.message ?? 'Unknown error');
    }
  },
);

export const fetchAvailableCraftingRecipes = createAsyncThunk(
  'craftingRecipe/available',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<CraftingRecipe[]>(`/crafting-recipe/available`);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data?.message ?? 'Unknown error');
    }
  },
);

export const fetchCraftingRecipe = createAsyncThunk(
  'craftingRecipe/item',
  async (params: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<CraftingRecipe>(`/crafting-recipe/${params}`);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data?.message ?? 'Unknown error');
    }
  },
);

export const createCraftingRecipe = createAsyncThunk(
  'craftingRecipe/createRecipe',
  async (params: CraftingRecipeCreateRequest, { rejectWithValue }) => {
    try {
      const form = createForm(params);
      const response = await axiosInstance.post(`/crafting-recipe`, form);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data?.message ?? 'Unknown error');
    }
  },
);

export const updateCraftingRecipe = createAsyncThunk(
  'craftingRecipe/updateRecipe',
  async (params: CraftingRecipeUpdateRequest, { rejectWithValue }) => {
    try {
      const form = createForm(params);
      const response = await axiosInstance.patch(`/crafting-recipe/${params.id}`, form);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data?.message ?? 'Unknown error');
    }
  },
);

const createForm = (inputs: CraftingRecipeCreateRequest | CraftingRecipeUpdateRequest): FormData => {
  const form = new FormData();
  Object.keys(inputs).forEach((inputKey) => {
    // @ts-ignore
    const value = inputs[inputKey];

    if (inputKey === 'image') {
      if (value !== undefined) {
        form.append(inputKey, value);
      }
    } else if (['requiredResources', 'outputResources'].includes(inputKey)) {
      // @ts-ignore
      form.append(inputKey, JSON.stringify(value));
    } else {
      if (value !== undefined) {
        form.append(inputKey, value);
      }
    }
  });
  return form;
};

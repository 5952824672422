export const FETCH_CAMPAIGNS_START = 'FETCH_CAMPAIGNS_START';
export const FETCH_CAMPAIGNS_SUCCESS = 'FETCH_CAMPAIGNS_SUCCESS';
export const FETCH_CAMPAIGNS_FAIL = 'FETCH_CAMPAIGNS_FAIL';

export const FETCH_CAMPAIGN_START = 'FETCH_CAMPAIGN_START';
export const FETCH_CAMPAIGN_SUCCESS = 'FETCH_CAMPAIGN_SUCCESS';
export const FETCH_CAMPAIGN_FAIL = 'FETCH_CAMPAIGN_FAIL';

export const UPDATE_CAMPAIGN_START = 'UPDATE_CAMPAIGN_START';
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_FAIL = 'UPDATE_CAMPAIGN_FAIL';

export const CREATE_CAMPAIGN_START = 'CREATE_CAMPAIGN_START';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAIL = 'CREATE_CAMPAIGN_FAIL';

export const DELETE_CAMPAIGN_START = 'DELETE_CAMPAIGN_START';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_FAIL = 'DELETE_CAMPAIGN_FAIL';

export type CampaignActionTypes =
  | typeof FETCH_CAMPAIGNS_START
  | typeof FETCH_CAMPAIGNS_SUCCESS
  | typeof FETCH_CAMPAIGNS_FAIL
  | typeof FETCH_CAMPAIGN_START
  | typeof FETCH_CAMPAIGN_SUCCESS
  | typeof FETCH_CAMPAIGN_FAIL
  | typeof UPDATE_CAMPAIGN_START
  | typeof UPDATE_CAMPAIGN_SUCCESS
  | typeof UPDATE_CAMPAIGN_FAIL
  | typeof CREATE_CAMPAIGN_START
  | typeof CREATE_CAMPAIGN_SUCCESS
  | typeof CREATE_CAMPAIGN_FAIL
  | typeof DELETE_CAMPAIGN_START
  | typeof DELETE_CAMPAIGN_SUCCESS
  | typeof DELETE_CAMPAIGN_FAIL;

export const FETCH_BLUEPRINTS_START = 'FETCH_BLUEPRINTS_START';
export const FETCH_BLUEPRINTS_SUCCESS = 'FETCH_BLUEPRINTS_SUCCESS';
export const FETCH_BLUEPRINTS_FAIL = 'FETCH_BLUEPRINTS_FAIL';

export const FETCH_BLUEPRINT_START = 'FETCH_BLUEPRINT_START';
export const FETCH_BLUEPRINT_SUCCESS = 'FETCH_BLUEPRINT_SUCCESS';
export const FETCH_BLUEPRINT_FAIL = 'FETCH_BLUEPRINT_FAIL';

export const UPDATE_BLUEPRINT_START = 'UPDATE_BLUEPRINT_START';
export const UPDATE_BLUEPRINT_SUCCESS = 'UPDATE_BLUEPRINT_SUCCESS';
export const UPDATE_BLUEPRINT_FAIL = 'UPDATE_BLUEPRINT_FAIL';

export const CREATE_BLUEPRINT_START = 'CREATE_BLUEPRINT_START';
export const CREATE_BLUEPRINT_SUCCESS = 'CREATE_BLUEPRINT_SUCCESS';
export const CREATE_BLUEPRINT_FAIL = 'CREATE_BLUEPRINT_FAIL';

export type BlueprintActionTypes =
  | typeof FETCH_BLUEPRINTS_START
  | typeof FETCH_BLUEPRINTS_SUCCESS
  | typeof FETCH_BLUEPRINTS_FAIL
  | typeof FETCH_BLUEPRINT_START
  | typeof FETCH_BLUEPRINT_SUCCESS
  | typeof FETCH_BLUEPRINT_FAIL
  | typeof UPDATE_BLUEPRINT_START
  | typeof UPDATE_BLUEPRINT_SUCCESS
  | typeof UPDATE_BLUEPRINT_FAIL
  | typeof CREATE_BLUEPRINT_START
  | typeof CREATE_BLUEPRINT_SUCCESS
  | typeof CREATE_BLUEPRINT_FAIL;

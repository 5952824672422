import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../config/Axios/axios-instance';
import { ListParams } from '../../hooks/useList/useList';
import { AxiosError } from 'axios';
import { MarsBattleBlock, MarsBattleBlockResource } from '../../domain/MarsBattleBlock';

export type MarsBattleBlockCreateRequest = {
  name: string;
  description: string;
  resources: MarsBattleBlockResource[];
};

export type MarsBattleBlockUpdateRequest = MarsBattleBlockCreateRequest & {
  id: number;
};

export const fetchMarsBattleBlocks = createAsyncThunk(
  'marsBattleBlocks/list',
  async (params: ListParams | undefined, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<MarsBattleBlock[]>(`/mars-battle/blocks`, { params });
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data?.message ?? 'Unknown error');
    }
  },
);

export const fetchMarsBattleBlock = createAsyncThunk(
  'marsBattleBlocks/item',
  async (params: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<MarsBattleBlock>(`/mars-battle/blocks/${params}`);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data?.message ?? 'Unknown error');
    }
  },
);

export const createMarsBattleBlock = createAsyncThunk(
  'marsBattleBlocks/createBlock',
  async (params: MarsBattleBlockCreateRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/mars-battle/blocks`, params);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data?.message ?? 'Unknown error');
    }
  },
);

export const updateMarsBattleBlock = createAsyncThunk(
  'marsBattleBlocks/updateBlock',
  async (params: MarsBattleBlockUpdateRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`/mars-battle/blocks/${params.id}`, params);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data?.message ?? 'Unknown error');
    }
  },
);

export const deleteMarsBattleBlock = createAsyncThunk(
  'marsBattleBlocks/deleteBlock',
  async (params: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/mars-battle/blocks/${params}`);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data?.message ?? 'Unknown error');
    }
  },
);

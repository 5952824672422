import { HttpError } from '../../config/Axios/axios-instance';
import { ColonistPart, ColonistPartsList } from '../../domain/ColonistPart';
import * as actionTypes from './actionTypes';
import { ColonistPartActionTypes } from './actionTypes';

export type ColonistPartStateType = {
  colonistParts: ColonistPartsList | null;
  colonistPartsLoading: boolean;
  colonistPartsError: HttpError;
  availableColonistParts: ColonistPart[];
  availableColonistPartsLoading: boolean;
  availableColonistPartsError: HttpError;
  colonistPart: ColonistPart | null;
  colonistPartLoading: boolean;
  colonistPartError: HttpError;
  colonistPartUpdateLoading: boolean;
  colonistPartUpdateError: HttpError;
  colonistPartUpdateSuccess: boolean;
  colonistPartCreateLoading: boolean;
  colonistPartCreateError: HttpError;
  colonistPartCreateSuccess: boolean;
  createdColonistPart: ColonistPart | null;
};

export type ColonistPartActionType = ColonistPartStateType & {
  type: ColonistPartActionTypes;
};

export const initialState: ColonistPartStateType = {
  colonistParts: null,
  colonistPartsLoading: false,
  colonistPartsError: null,
  availableColonistParts: [],
  availableColonistPartsLoading: false,
  availableColonistPartsError: null,
  colonistPart: null,
  colonistPartLoading: false,
  colonistPartError: null,
  colonistPartUpdateLoading: false,
  colonistPartUpdateError: null,
  colonistPartUpdateSuccess: false,
  colonistPartCreateLoading: false,
  colonistPartCreateError: null,
  colonistPartCreateSuccess: false,
  createdColonistPart: null,
};

const fetchColonistPartsStart = (state: ColonistPartStateType): ColonistPartStateType => ({
  ...state,
  colonistPartsLoading: true,
  colonistPart: null,
  colonistPartCreateSuccess: false,
  colonistPartUpdateSuccess: false,
});

const fetchColonistPartsSuccess = (
  state: ColonistPartStateType,
  action: ColonistPartActionType,
): ColonistPartStateType => ({
  ...state,
  colonistParts: action.colonistParts,
  colonistPartsLoading: false,
  colonistPartsError: null,
});

const fetchColonistPartsFail = (
  state: ColonistPartStateType,
  action: ColonistPartActionType,
): ColonistPartStateType => ({
  ...state,
  colonistPartsError: action.colonistPartsError,
  colonistPartsLoading: false,
});

const fetchAvailableColonistPartsStart = (state: ColonistPartStateType): ColonistPartStateType => ({
  ...state,
  availableColonistPartsLoading: true,
});

const fetchAvailableColonistPartsSuccess = (
  state: ColonistPartStateType,
  action: ColonistPartActionType,
): ColonistPartStateType => ({
  ...state,
  availableColonistParts: action.availableColonistParts,
  availableColonistPartsLoading: false,
  availableColonistPartsError: null,
});

const fetchAvailableColonistPartsFail = (
  state: ColonistPartStateType,
  action: ColonistPartActionType,
): ColonistPartStateType => ({
  ...state,
  availableColonistPartsError: action.colonistPartsError,
  availableColonistPartsLoading: false,
});

const fetchColonistPartStart = (state: ColonistPartStateType): ColonistPartStateType => ({
  ...state,
  colonistPartLoading: true,
  colonistPartCreateSuccess: false,
  colonistPartUpdateSuccess: false,
  colonistPartUpdateError: null,
  colonistPartCreateError: null,
});

const fetchColonistPartSuccess = (
  state: ColonistPartStateType,
  action: ColonistPartActionType,
): ColonistPartStateType => ({
  ...state,
  colonistPart: action.colonistPart,
  colonistPartLoading: false,
  colonistPartError: null,
});

const fetchColonistPartFail = (
  state: ColonistPartStateType,
  action: ColonistPartActionType,
): ColonistPartStateType => ({
  ...state,
  colonistPartError: action.colonistPartError,
  colonistPartLoading: false,
});

const updateColonistPartStart = (state: ColonistPartStateType): ColonistPartStateType => ({
  ...state,
  colonistPartUpdateLoading: true,
});

const updateColonistPartSuccess = (state: ColonistPartStateType): ColonistPartStateType => ({
  ...state,
  colonistPartUpdateLoading: false,
  colonistPartUpdateError: null,
  colonistPartUpdateSuccess: true,
});

const updateColonistPartFail = (
  state: ColonistPartStateType,
  action: ColonistPartActionType,
): ColonistPartStateType => ({
  ...state,
  colonistPartUpdateLoading: false,
  colonistPartUpdateError: action.colonistPartUpdateError,
});

const createColonistPartStart = (state: ColonistPartStateType): ColonistPartStateType => ({
  ...state,
  colonistPartCreateLoading: true,
});

const createColonistPartSuccess = (
  state: ColonistPartStateType,
  action: ColonistPartActionType,
): ColonistPartStateType => ({
  ...state,
  colonistPartCreateLoading: false,
  colonistPartCreateError: null,
  colonistPartCreateSuccess: true,
  createdColonistPart: action.createdColonistPart,
});

const createColonistPartFail = (
  state: ColonistPartStateType,
  action: ColonistPartActionType,
): ColonistPartStateType => ({
  ...state,
  colonistPartCreateLoading: false,
  colonistPartCreateError: action.colonistPartCreateError,
});

const reducer = (state = initialState, action: ColonistPartActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_COLONIST_PARTS_START:
      return fetchColonistPartsStart(state);
    case actionTypes.FETCH_COLONIST_PARTS_SUCCESS:
      return fetchColonistPartsSuccess(state, action);
    case actionTypes.FETCH_COLONIST_PARTS_FAIL:
      return fetchColonistPartsFail(state, action);
    case actionTypes.FETCH_AVAILABLE_COLONIST_PARTS_START:
      return fetchAvailableColonistPartsStart(state);
    case actionTypes.FETCH_AVAILABLE_COLONIST_PARTS_SUCCESS:
      return fetchAvailableColonistPartsSuccess(state, action);
    case actionTypes.FETCH_AVAILABLE_COLONIST_PARTS_FAIL:
      return fetchAvailableColonistPartsFail(state, action);
    case actionTypes.FETCH_COLONIST_PART_START:
      return fetchColonistPartStart(state);
    case actionTypes.FETCH_COLONIST_PART_SUCCESS:
      return fetchColonistPartSuccess(state, action);
    case actionTypes.FETCH_COLONIST_PART_FAIL:
      return fetchColonistPartFail(state, action);
    case actionTypes.UPDATE_COLONIST_PART_START:
      return updateColonistPartStart(state);
    case actionTypes.UPDATE_COLONIST_PART_SUCCESS:
      return updateColonistPartSuccess(state);
    case actionTypes.UPDATE_COLONIST_PART_FAIL:
      return updateColonistPartFail(state, action);
    case actionTypes.CREATE_COLONIST_PART_START:
      return createColonistPartStart(state);
    case actionTypes.CREATE_COLONIST_PART_SUCCESS:
      return createColonistPartSuccess(state, action);
    case actionTypes.CREATE_COLONIST_PART_FAIL:
      return createColonistPartFail(state, action);
    default:
      return state;
  }
};

export default reducer;

import axios from 'axios';

export type HttpError = null | string | Array<{ [key: string]: string }>;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token && config.headers) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 413) {
      error.response['data'] = {
        statusCode: 413,
        message: 'File too large (max 2MB)',
        error: 'Payload Too Large',
      };
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../config/Axios/axios-instance';
import { Level, LevelList } from '../../domain/Level';
import { showToast } from '../../utility/toast/toast';
import { ListParams } from '../../hooks/useList/useList';
import { AxiosError } from 'axios';

export type LevelCreateRequest = {
  level: number;
  rewardType?: string;
  rewardId?: number;
  rewardQuantity?: number;
  experience: number;
};

export type LevelUpdateRequest = {
  level: number;
};

export const fetchLevelList = createAsyncThunk(
  'levels/levelList',
  async (params: ListParams | undefined, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<LevelList>(`/levels/all`, { params });
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data?.message ?? 'Unknown error');
    }
  },
);

export const fetchLevel = createAsyncThunk('levels/getLevel', async (levelId: number, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get<Level>(`/levels/${levelId}`);
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    return rejectWithValue(err.response?.data?.message ?? 'Unknown error');
  }
});

export const updateLevel = createAsyncThunk(
  'levels/updateLevel',
  async (inputs: LevelUpdateRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`/levels/${inputs.level}`, inputs);
      showToast('Level was successfully updated!', 'success');
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data?.message ?? 'Unknown error');
    }
  },
);

export const createLevel = createAsyncThunk(
  'levels/createLevel',
  async (inputs: LevelCreateRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/levels`, inputs);
      showToast('Level was successfully created!', 'success');
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data?.message ?? 'Unknown error');
    }
  },
);

export const fetchNextLevel = createAsyncThunk('levels/nextLevel', async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get<{ level: number }>(`/levels/next`);
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    return rejectWithValue(err.response?.data?.message ?? 'Unknown error');
  }
});

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HttpError } from '../../config/Axios/axios-instance';
import { Discount, DiscountsList } from '../../domain/Discount';
import {
  createLandDiscount,
  createMccDiscount,
  fetchLandDiscount,
  fetchLandDiscounts,
  fetchMccDiscount,
  fetchMccDiscounts,
  updateLandDiscount,
  updateMccDiscount,
} from './actions';

interface DiscountStateType {
  mccDiscounts: DiscountsList | null;
  mccDiscountsLoading: boolean;
  mccDiscountsError: HttpError;
  mccDiscount: Discount | null;
  mccDiscountLoading: boolean;
  mccDiscountError: HttpError;
  mccDiscountUpdateLoading: boolean;
  mccDiscountUpdateError: HttpError;
  mccDiscountUpdateSuccess: boolean;
  mccDiscountCreateLoading: boolean;
  mccDiscountCreateError: HttpError;
  mccDiscountCreateSuccess: boolean;
  mccDiscountDeleteLoading: boolean;
  mccDiscountDeleteError: HttpError;
  mccDiscountDeleteSuccess: boolean;
  landDiscounts: DiscountsList | null;
  landDiscountsLoading: boolean;
  landDiscountsError: HttpError;
  landDiscount: Discount | null;
  landDiscountLoading: boolean;
  landDiscountError: HttpError;
  landDiscountUpdateLoading: boolean;
  landDiscountUpdateError: HttpError;
  landDiscountUpdateSuccess: boolean;
  landDiscountCreateLoading: boolean;
  landDiscountCreateError: HttpError;
  landDiscountCreateSuccess: boolean;
  landDiscountDeleteLoading: boolean;
  landDiscountDeleteError: HttpError;
  landDiscountDeleteSuccess: boolean;
}

const initialState: DiscountStateType = {
  mccDiscounts: null,
  mccDiscountsLoading: false,
  mccDiscountsError: null,
  mccDiscount: null,
  mccDiscountLoading: false,
  mccDiscountError: null,
  mccDiscountUpdateLoading: false,
  mccDiscountUpdateError: null,
  mccDiscountUpdateSuccess: false,
  mccDiscountCreateLoading: false,
  mccDiscountCreateError: null,
  mccDiscountCreateSuccess: false,
  mccDiscountDeleteLoading: false,
  mccDiscountDeleteError: null,
  mccDiscountDeleteSuccess: false,
  landDiscounts: null,
  landDiscountsLoading: false,
  landDiscountsError: null,
  landDiscount: null,
  landDiscountLoading: false,
  landDiscountError: null,
  landDiscountUpdateLoading: false,
  landDiscountUpdateError: null,
  landDiscountUpdateSuccess: false,
  landDiscountCreateLoading: false,
  landDiscountCreateError: null,
  landDiscountCreateSuccess: false,
  landDiscountDeleteLoading: false,
  landDiscountDeleteError: null,
  landDiscountDeleteSuccess: false,
};

const discountSlice = createSlice({
  name: 'discount',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMccDiscounts.fulfilled.type]: (state, action: PayloadAction<Discount[]>) => {
      state.mccDiscounts = action.payload;
      state.mccDiscountsLoading = false;
      state.mccDiscountsError = null;
    },
    [fetchMccDiscounts.pending.type]: (state) => {
      state.mccDiscountsLoading = true;
      state.mccDiscount = null;
      state.mccDiscountCreateSuccess = false;
      state.mccDiscountUpdateSuccess = false;
    },
    [fetchMccDiscounts.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.mccDiscountsLoading = false;
      state.mccDiscountsError = action.payload;
    },
    [fetchLandDiscounts.fulfilled.type]: (state, action: PayloadAction<Discount[]>) => {
      state.landDiscounts = action.payload;
      state.landDiscountsLoading = false;
      state.landDiscountsError = null;
    },
    [fetchLandDiscounts.pending.type]: (state) => {
      state.landDiscountsLoading = true;
      state.landDiscount = null;
      state.landDiscountCreateSuccess = false;
      state.landDiscountUpdateSuccess = false;
    },
    [fetchLandDiscounts.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.landDiscountsLoading = false;
      state.landDiscountsError = action.payload;
    },
    [fetchMccDiscount.fulfilled.type]: (state, action: PayloadAction<Discount>) => {
      state.mccDiscount = action.payload;
      state.mccDiscountLoading = false;
      state.mccDiscountError = null;
    },
    [fetchMccDiscount.pending.type]: (state) => {
      state.mccDiscountLoading = true;
      state.mccDiscountCreateSuccess = false;
      state.mccDiscountUpdateSuccess = false;
      state.mccDiscountUpdateError = null;
      state.mccDiscountCreateError = null;
    },
    [fetchMccDiscount.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.mccDiscountLoading = false;
      state.mccDiscountError = action.payload;
    },
    [fetchLandDiscount.fulfilled.type]: (state, action: PayloadAction<Discount>) => {
      state.landDiscount = action.payload;
      state.landDiscountLoading = false;
      state.landDiscountError = null;
    },
    [fetchLandDiscount.pending.type]: (state) => {
      state.landDiscountLoading = true;
      state.landDiscountCreateSuccess = false;
      state.landDiscountUpdateSuccess = false;
      state.landDiscountUpdateError = null;
      state.landDiscountCreateError = null;
    },
    [fetchLandDiscount.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.landDiscountLoading = false;
      state.landDiscountError = action.payload;
    },
    [updateMccDiscount.fulfilled.type]: (state) => {
      state.mccDiscountUpdateSuccess = true;
      state.mccDiscountUpdateLoading = false;
      state.mccDiscountUpdateError = null;
    },
    [updateMccDiscount.pending.type]: (state) => {
      state.mccDiscountUpdateLoading = true;
    },
    [updateMccDiscount.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.mccDiscountUpdateLoading = false;
      state.mccDiscountUpdateError = action.payload;
    },
    [updateLandDiscount.fulfilled.type]: (state) => {
      state.landDiscountUpdateSuccess = true;
      state.landDiscountUpdateLoading = false;
      state.landDiscountUpdateError = null;
    },
    [updateLandDiscount.pending.type]: (state) => {
      state.landDiscountUpdateLoading = true;
    },
    [updateLandDiscount.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.landDiscountUpdateLoading = false;
      state.landDiscountUpdateError = action.payload;
    },
    [createMccDiscount.fulfilled.type]: (state) => {
      state.mccDiscountCreateLoading = false;
      state.mccDiscountCreateError = null;
      state.mccDiscountCreateSuccess = true;
    },
    [createMccDiscount.pending.type]: (state) => {
      state.mccDiscountCreateLoading = true;
    },
    [createMccDiscount.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.mccDiscountCreateLoading = false;
      state.mccDiscountCreateError = action.payload;
    },
    [createLandDiscount.fulfilled.type]: (state) => {
      state.landDiscountCreateLoading = false;
      state.landDiscountCreateError = null;
      state.landDiscountCreateSuccess = true;
    },
    [createLandDiscount.pending.type]: (state) => {
      state.landDiscountCreateLoading = true;
    },
    [createLandDiscount.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.landDiscountCreateLoading = false;
      state.landDiscountCreateError = action.payload;
    },
  },
});

export const discountActions = discountSlice.actions;

const discountReducer = discountSlice.reducer;
export default discountReducer;

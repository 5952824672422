export const FETCH_HOME_BANNERS_START = 'FETCH_HOME_BANNERS_START';
export const FETCH_HOME_BANNERS_SUCCESS = 'FETCH_HOME_BANNERS_SUCCESS';
export const FETCH_HOME_BANNERS_FAIL = 'FETCH_HOME_BANNERS_FAIL';

export const FETCH_HOME_BANNER_START = 'FETCH_HOME_BANNER_START';
export const FETCH_HOME_BANNER_SUCCESS = 'FETCH_HOME_BANNER_SUCCESS';
export const FETCH_HOME_BANNER_FAIL = 'FETCH_HOME_BANNER_FAIL';

export const UPDATE_HOME_BANNER_START = 'UPDATE_HOME_BANNER_START';
export const UPDATE_HOME_BANNER_SUCCESS = 'UPDATE_HOME_BANNER_SUCCESS';
export const UPDATE_HOME_BANNER_FAIL = 'UPDATE_HOME_BANNER_FAIL';

export const CREATE_HOME_BANNER_START = 'CREATE_HOME_BANNER_START';
export const CREATE_HOME_BANNER_SUCCESS = 'CREATE_HOME_BANNER_SUCCESS';
export const CREATE_HOME_BANNER_FAIL = 'CREATE_HOME_BANNER_FAIL';

export type HomeBannerActionTypes =
  | typeof FETCH_HOME_BANNERS_START
  | typeof FETCH_HOME_BANNERS_SUCCESS
  | typeof FETCH_HOME_BANNERS_FAIL
  | typeof FETCH_HOME_BANNER_START
  | typeof FETCH_HOME_BANNER_SUCCESS
  | typeof FETCH_HOME_BANNER_FAIL
  | typeof UPDATE_HOME_BANNER_START
  | typeof UPDATE_HOME_BANNER_SUCCESS
  | typeof UPDATE_HOME_BANNER_FAIL
  | typeof CREATE_HOME_BANNER_START
  | typeof CREATE_HOME_BANNER_SUCCESS
  | typeof CREATE_HOME_BANNER_FAIL;

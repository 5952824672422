export const routes = {
  items: '/products',
  itemCreate: '/products/new',
  itemEdit: '/products/:id',
  users: '/users',
  usersId: '/users/:id',
  ledger: '/ledger',
  settingsEdit: '/settings',
  chatMessages: '/chat-messages',
  translations: '/translations',
  login: '/login',
  blueprints: '/blueprints',
  blueprintCreate: '/blueprints/new',
  blueprintEdit: '/blueprints/:id',
  statistics: '/statistics',
  homeBanners: '/home-banners',
  homeBannersCreate: '/home-banners/new',
  homeBannersEdit: '/home-banners/:id',
  campaigns: '/campaigns',
  campaignCreate: '/campaigns/new',
  campaignEdit: '/campaigns/:id',
  badges: '/badges',
  badgeCreate: '/badges/new',
  badgeEdit: '/badges/:id',
  levels: '/levels',
  levelCreate: '/levels/new',
  levelEdit: '/levels/:id',
  mccDiscounts: '/discounts/mcc',
  mccDiscountCreate: '/discounts/mcc/new',
  mccDiscountEdit: '/discounts/mcc/:id',
  landDiscounts: '/discounts/land',
  landDiscountCreate: '/discounts/land/new',
  landDiscountEdit: '/discounts/land/:id',
  colonistParts: '/colonistParts',
  colonistPartCreate: '/colonistParts/new',
  colonistPartEdit: '/colonistParts/:id',
  blueprintMysteryBox: '/blueprint-mystery-box',
  affiliate: '/affiliate',
  affiliateUser: '/affiliate/:userId',
  jumpTask: '/jump-task',
  craftingRecipe: '/crafting-recipe',
  craftingRecipeCreate: '/crafting-recipe/new',
  craftingRecipeEdit: '/crafting-recipe/:id',
  resources: '/resources',
  resourceCreate: '/resources/new',
  resourceEdit: '/resources/:id',
  marsBattleBlocks: '/mars-battle-blocks',
  marsBattleBlockCreate: '/mars-battle-blocks/new',
  marsBattleBlockEdit: '/mars-battle-blocks/:id',
};

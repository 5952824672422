import { HttpError } from '../../config/Axios/axios-instance';
import * as actionTypes from './actionTypes';
import { SettingsActionTypes } from './actionTypes';
import { Settings } from '../../domain/Settings';

export type SettingsStateType = {
  settings: Settings[];
  settingsLoading: boolean;
  settingsError: HttpError;
  settingsUpdateLoading: boolean;
  settingsUpdateError: HttpError;
  settingsUpdateSuccess: boolean;
};

export type SettingsActionType = SettingsStateType & {
  type: SettingsActionTypes;
};

export const initialState: SettingsStateType = {
  settings: [],
  settingsLoading: false,
  settingsError: null,
  settingsUpdateLoading: false,
  settingsUpdateError: null,
  settingsUpdateSuccess: false,
};

const fetchSettingsStart = (state: SettingsStateType): SettingsStateType => ({
  ...state,
  settingsLoading: true,
  settingsUpdateSuccess: false,
});

const fetchSettingsSuccess = (state: SettingsStateType, action: SettingsActionType): SettingsStateType => ({
  ...state,
  settings: action.settings,
  settingsLoading: false,
  settingsError: null,
});

const fetchSettingsFail = (state: SettingsStateType, action: SettingsActionType): SettingsStateType => ({
  ...state,
  settingsError: action.settingsError,
  settingsLoading: false,
});

const updateSettingsStart = (state: SettingsStateType): SettingsStateType => ({
  ...state,
  settingsUpdateLoading: true,
});

const updateSettingsSuccess = (state: SettingsStateType): SettingsStateType => ({
  ...state,
  settingsUpdateLoading: false,
  settingsUpdateError: null,
  settingsUpdateSuccess: true,
});

const updateSettingsFail = (state: SettingsStateType, action: SettingsActionType): SettingsStateType => ({
  ...state,
  settingsUpdateLoading: false,
  settingsUpdateError: action.settingsUpdateError,
});

const reducer = (state = initialState, action: SettingsActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_SETTINGS_START:
      return fetchSettingsStart(state);
    case actionTypes.FETCH_SETTINGS_SUCCESS:
      return fetchSettingsSuccess(state, action);
    case actionTypes.FETCH_SETTINGS_FAIL:
      return fetchSettingsFail(state, action);
    case actionTypes.UPDATE_SETTINGS_START:
      return updateSettingsStart(state);
    case actionTypes.UPDATE_SETTINGS_SUCCESS:
      return updateSettingsSuccess(state);
    case actionTypes.UPDATE_SETTINGS_FAIL:
      return updateSettingsFail(state, action);
    default:
      return state;
  }
};

export default reducer;

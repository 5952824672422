export const FETCH_SETTINGS_START = 'FETCH_SETTINGS_START';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAIL = 'FETCH_SETTINGS_FAIL';

export const UPDATE_SETTINGS_START = 'UPDATE_SETTINGS_START';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAIL = 'UPDATE_SETTINGS_FAIL';

export type SettingsActionTypes =
  | typeof FETCH_SETTINGS_START
  | typeof FETCH_SETTINGS_SUCCESS
  | typeof FETCH_SETTINGS_FAIL
  | typeof UPDATE_SETTINGS_START
  | typeof UPDATE_SETTINGS_SUCCESS
  | typeof UPDATE_SETTINGS_FAIL;

export const FETCH_COLONIST_PARTS_START = 'FETCH_COLONIST_PARTS_START';
export const FETCH_COLONIST_PARTS_SUCCESS = 'FETCH_COLONIST_PARTS_SUCCESS';
export const FETCH_COLONIST_PARTS_FAIL = 'FETCH_COLONIST_PARTS_FAIL';

export const FETCH_COLONIST_PART_START = 'FETCH_COLONIST_PART_START';
export const FETCH_COLONIST_PART_SUCCESS = 'FETCH_COLONIST_PART_SUCCESS';
export const FETCH_COLONIST_PART_FAIL = 'FETCH_COLONIST_PART_FAIL';

export const UPDATE_COLONIST_PART_START = 'UPDATE_COLONIST_PART_START';
export const UPDATE_COLONIST_PART_SUCCESS = 'UPDATE_COLONIST_PART_SUCCESS';
export const UPDATE_COLONIST_PART_FAIL = 'UPDATE_COLONIST_PART_FAIL';

export const CREATE_COLONIST_PART_START = 'CREATE_COLONIST_PART_START';
export const CREATE_COLONIST_PART_SUCCESS = 'CREATE_COLONIST_PART_SUCCESS';
export const CREATE_COLONIST_PART_FAIL = 'CREATE_COLONIST_PART_FAIL';

export const FETCH_AVAILABLE_COLONIST_PARTS_START = 'FETCH_AVAILABLE_COLONIST_PARTS_START';
export const FETCH_AVAILABLE_COLONIST_PARTS_SUCCESS = 'FETCH_AVAILABLE_COLONIST_PARTS_SUCCESS';
export const FETCH_AVAILABLE_COLONIST_PARTS_FAIL = 'FETCH_AVAILABLE_COLONIST_PARTS_FAIL';

export type ColonistPartActionTypes =
  | typeof FETCH_COLONIST_PARTS_START
  | typeof FETCH_COLONIST_PARTS_SUCCESS
  | typeof FETCH_COLONIST_PARTS_FAIL
  | typeof FETCH_COLONIST_PART_START
  | typeof FETCH_COLONIST_PART_SUCCESS
  | typeof FETCH_COLONIST_PART_FAIL
  | typeof UPDATE_COLONIST_PART_START
  | typeof UPDATE_COLONIST_PART_SUCCESS
  | typeof UPDATE_COLONIST_PART_FAIL
  | typeof CREATE_COLONIST_PART_START
  | typeof CREATE_COLONIST_PART_SUCCESS
  | typeof CREATE_COLONIST_PART_FAIL
  | typeof FETCH_AVAILABLE_COLONIST_PARTS_START
  | typeof FETCH_AVAILABLE_COLONIST_PARTS_SUCCESS
  | typeof FETCH_AVAILABLE_COLONIST_PARTS_FAIL;

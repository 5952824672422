import { HttpError } from '../../config/Axios/axios-instance';
import { ExternalItem, Item, ItemsList } from '../../domain/Item';
import * as actionTypes from './actionTypes';
import { ItemActionTypes } from './actionTypes';

export type ItemStateType = {
  items: ItemsList | null;
  itemsLoading: boolean;
  itemsError: HttpError;
  item: Item | null;
  itemLoading: boolean;
  itemError: HttpError;
  externalItems: ExternalItem[];
  externalItemsLoading: boolean;
  externalItemsError: HttpError;
  itemUpdateLoading: boolean;
  itemUpdateError: HttpError;
  itemUpdateSuccess: boolean;
  itemCreateLoading: boolean;
  itemCreateError: HttpError;
  itemCreateSuccess: boolean;
  createdItem: Item | null;
};

export type ItemActionType = ItemStateType & {
  type: ItemActionTypes;
};

export const initialState: ItemStateType = {
  items: null,
  itemsLoading: false,
  itemsError: null,
  item: null,
  itemLoading: false,
  itemError: null,
  externalItems: [],
  externalItemsLoading: false,
  externalItemsError: null,
  itemUpdateLoading: false,
  itemUpdateError: null,
  itemUpdateSuccess: false,
  itemCreateLoading: false,
  itemCreateError: null,
  itemCreateSuccess: false,
  createdItem: null,
};

const fetchItemsStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  itemsLoading: true,
  item: null,
  itemCreateSuccess: false,
  itemUpdateSuccess: false,
});

const fetchItemsSuccess = (state: ItemStateType, action: ItemActionType): ItemStateType => ({
  ...state,
  items: action.items,
  itemsLoading: false,
  itemsError: null,
});

const fetchItemsFail = (state: ItemStateType, action: ItemActionType): ItemStateType => ({
  ...state,
  itemsError: action.itemsError,
  itemsLoading: false,
});

const fetchItemStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  itemLoading: true,
  itemCreateSuccess: false,
  itemUpdateSuccess: false,
  itemUpdateError: null,
  itemCreateError: null,
});

const fetchItemSuccess = (state: ItemStateType, action: ItemActionType): ItemStateType => ({
  ...state,
  item: action.item,
  itemLoading: false,
  itemError: null,
});

const fetchItemFail = (state: ItemStateType, action: ItemActionType): ItemStateType => ({
  ...state,
  itemError: action.itemError,
  itemLoading: false,
});

const fetchExternalItemsStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  externalItemsLoading: true,
});

const fetchExternalItemsSuccess = (state: ItemStateType, action: ItemActionType): ItemStateType => ({
  ...state,
  externalItems: action.externalItems,
  externalItemsLoading: false,
  externalItemsError: null,
});

const fetchExternalItemsFail = (state: ItemStateType, action: ItemActionType): ItemStateType => ({
  ...state,
  externalItemsError: action.externalItemsError,
  externalItemsLoading: false,
});

const updateItemStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  itemUpdateLoading: true,
});

const updateItemSuccess = (state: ItemStateType): ItemStateType => ({
  ...state,
  itemUpdateLoading: false,
  itemUpdateError: null,
  itemUpdateSuccess: true,
});

const updateItemFail = (state: ItemStateType, action: ItemActionType): ItemStateType => ({
  ...state,
  itemUpdateLoading: false,
  itemUpdateError: action.itemUpdateError,
});

const createItemStart = (state: ItemStateType): ItemStateType => ({
  ...state,
  itemCreateLoading: true,
});

const createItemSuccess = (state: ItemStateType, action: ItemActionType): ItemStateType => ({
  ...state,
  itemCreateLoading: false,
  itemCreateError: null,
  itemCreateSuccess: true,
  createdItem: action.createdItem,
});

const createItemFail = (state: ItemStateType, action: ItemActionType): ItemStateType => ({
  ...state,
  itemCreateLoading: false,
  itemCreateError: action.itemCreateError,
});

const reducer = (state = initialState, action: ItemActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ITEMS_START:
      return fetchItemsStart(state);
    case actionTypes.FETCH_ITEMS_SUCCESS:
      return fetchItemsSuccess(state, action);
    case actionTypes.FETCH_ITEMS_FAIL:
      return fetchItemsFail(state, action);
    case actionTypes.FETCH_ITEM_START:
      return fetchItemStart(state);
    case actionTypes.FETCH_ITEM_SUCCESS:
      return fetchItemSuccess(state, action);
    case actionTypes.FETCH_ITEM_FAIL:
      return fetchItemFail(state, action);
    case actionTypes.FETCH_EXTERNAL_ITEMS_START:
      return fetchExternalItemsStart(state);
    case actionTypes.FETCH_EXTERNAL_ITEMS_SUCCESS:
      return fetchExternalItemsSuccess(state, action);
    case actionTypes.FETCH_EXTERNAL_ITEMS_FAIL:
      return fetchExternalItemsFail(state, action);
    case actionTypes.UPDATE_ITEM_START:
      return updateItemStart(state);
    case actionTypes.UPDATE_ITEM_SUCCESS:
      return updateItemSuccess(state);
    case actionTypes.UPDATE_ITEM_FAIL:
      return updateItemFail(state, action);
    case actionTypes.CREATE_ITEM_START:
      return createItemStart(state);
    case actionTypes.CREATE_ITEM_SUCCESS:
      return createItemSuccess(state, action);
    case actionTypes.CREATE_ITEM_FAIL:
      return createItemFail(state, action);
    default:
      return state;
  }
};

export default reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HttpError } from '../../config/Axios/axios-instance';
import { Level, LevelList } from '../../domain/Level';
import { createLevel, fetchLevel, fetchLevelList, fetchNextLevel, updateLevel } from './actions';

export type LevelsStateType = {
  levels: LevelList | null;
  levelsLoading: boolean;
  levelsError: HttpError;
  level: Level | null;
  levelLoading: boolean;
  levelError: HttpError;
  levelUpdateLoading: boolean;
  levelUpdateError: HttpError;
  levelUpdateSuccess: boolean;
  levelCreateLoading: boolean;
  levelCreateError: HttpError;
  levelCreateSuccess: boolean;
  nextLevel: number;
  nextLevelLoading: boolean;
};

export const initialState: LevelsStateType = {
  levels: null,
  levelsLoading: false,
  levelsError: null,
  level: null,
  levelLoading: false,
  levelError: null,
  levelUpdateLoading: false,
  levelUpdateError: null,
  levelUpdateSuccess: false,
  levelCreateLoading: false,
  levelCreateError: null,
  levelCreateSuccess: false,
  nextLevel: 0,
  nextLevelLoading: false,
};

const levelsSlice = createSlice({
  name: 'levels',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchLevelList.fulfilled.type]: (state, action: PayloadAction<LevelList>) => {
      state.levels = action.payload;
      state.levelsLoading = false;
      state.levelsError = null;
    },
    [fetchLevelList.pending.type]: (state) => {
      state.levelsLoading = true;
      state.level = null;
      state.levelCreateSuccess = false;
      state.levelUpdateSuccess = false;
    },
    [fetchLevelList.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.levelsLoading = false;
      state.levelsError = action.payload;
    },
    [fetchLevel.fulfilled.type]: (state, action: PayloadAction<Level>) => {
      state.level = action.payload;
      state.levelLoading = false;
      state.levelError = null;
    },
    [fetchLevel.pending.type]: (state) => {
      state.levelLoading = true;
      state.levelCreateSuccess = false;
      state.levelUpdateSuccess = false;
      state.levelUpdateError = null;
      state.levelCreateError = null;
    },
    [fetchLevel.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.levelLoading = false;
      state.levelError = action.payload;
    },
    [updateLevel.fulfilled.type]: (state) => {
      state.levelUpdateLoading = false;
      state.levelUpdateError = null;
      state.levelUpdateSuccess = true;
    },
    [updateLevel.pending.type]: (state) => {
      state.levelUpdateLoading = true;
    },
    [updateLevel.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.levelUpdateLoading = false;
      state.levelUpdateError = action.payload;
    },
    [createLevel.fulfilled.type]: (state, action) => {
      console.log(`Create fulfilled`);
      console.log(action.payload);
      state.levelCreateLoading = false;
      state.levelCreateError = null;
      state.levelCreateSuccess = true;
    },
    [createLevel.pending.type]: (state) => {
      state.levelCreateLoading = true;
    },
    [createLevel.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      console.log(`Create rejected`);
      console.log(action.payload);
      state.levelCreateLoading = false;
      state.levelCreateError = action.payload;
    },
    [fetchNextLevel.fulfilled.type]: (state, action: PayloadAction<{ level: number }>) => {
      state.nextLevel = action.payload.level;
      state.nextLevelLoading = false;
    },
    [fetchNextLevel.pending.type]: (state) => {
      state.nextLevelLoading = true;
    },
    [fetchNextLevel.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.nextLevelLoading = false;
    },
  },
});

export const levelsActions = levelsSlice.actions;

const levelsReducer = levelsSlice.reducer;
export default levelsReducer;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HttpError } from '../../config/Axios/axios-instance';
import { fetchUserStats } from './actions';
import { UserStatistics } from '../../domain/User';

interface UserDetailsStateType {
  userStatistics: UserStatistics | null;
  userStatisticsLoading: boolean;
  userStatisticsError: HttpError;
}

const initialState: UserDetailsStateType = {
  userStatistics: null,
  userStatisticsLoading: false,
  userStatisticsError: null,
};

const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUserStats.fulfilled.type]: (state, action: PayloadAction<UserStatistics>) => {
      state.userStatistics = action.payload;
      state.userStatisticsLoading = false;
      state.userStatisticsError = null;
    },
    [fetchUserStats.pending.type]: (state) => {
      state.userStatisticsLoading = true;
    },
    [fetchUserStats.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.userStatisticsLoading = false;
      state.userStatisticsError = action.payload;
    },
  },
});

export const userDetailsActions = userDetailsSlice.actions;

const userDetailsReducer = userDetailsSlice.reducer;
export default userDetailsReducer;

export const FETCH_BLUEPRINT_MYSTERY_BOX_PRICE_START = 'FETCH_BLUEPRINT_MYSTERY_BOX_PRICE_START';
export const FETCH_BLUEPRINT_MYSTERY_BOX_PRICE_SUCCESS = 'FETCH_BLUEPRINT_MYSTERY_BOX_PRICE_SUCCESS';
export const FETCH_BLUEPRINT_MYSTERY_BOX_PRICE_FAIL = 'FETCH_BLUEPRINT_MYSTERY_BOX_PRICE_FAIL';

export const FETCH_BLUEPRINT_MYSTERY_BOX_REWARDS_START = 'FETCH_BLUEPRINT_MYSTERY_BOX_REWARDS_START';
export const FETCH_BLUEPRINT_MYSTERY_BOX_REWARDS_SUCCESS = 'FETCH_BLUEPRINT_MYSTERY_BOX_REWARDS_SUCCESS';
export const FETCH_BLUEPRINT_MYSTERY_BOX_REWARDS_FAIL = 'FETCH_BLUEPRINT_MYSTERY_BOX_REWARDS_FAIL';

export const UPDATE_BLUEPRINT_MYSTERY_BOX_PRICE_START = 'UPDATE_BLUEPRINT_MYSTERY_BOX_PRICE_START';
export const UPDATE_BLUEPRINT_MYSTERY_BOX_PRICE_SUCCESS = 'UPDATE_BLUEPRINT_MYSTERY_BOX_PRICE_SUCCESS';
export const UPDATE_BLUEPRINT_MYSTERY_BOX_PRICE_FAIL = 'UPDATE_BLUEPRINT_MYSTERY_BOX_PRICE_FAIL';

export const UPDATE_BLUEPRINT_MYSTERY_BOX_REWARDS_START = 'UPDATE_BLUEPRINT_MYSTERY_BOX_REWARDS_START';
export const UPDATE_BLUEPRINT_MYSTERY_BOX_REWARDS_SUCCESS = 'UPDATE_BLUEPRINT_MYSTERY_BOX_REWARDS_SUCCESS';
export const UPDATE_BLUEPRINT_MYSTERY_BOX_REWARDS_FAIL = 'UPDATE_BLUEPRINT_MYSTERY_BOX_REWARDS_FAIL';

export type BlueprintMysteryBoxActionTypes =
  | typeof FETCH_BLUEPRINT_MYSTERY_BOX_PRICE_START
  | typeof FETCH_BLUEPRINT_MYSTERY_BOX_PRICE_SUCCESS
  | typeof FETCH_BLUEPRINT_MYSTERY_BOX_PRICE_FAIL
  | typeof FETCH_BLUEPRINT_MYSTERY_BOX_REWARDS_START
  | typeof FETCH_BLUEPRINT_MYSTERY_BOX_REWARDS_SUCCESS
  | typeof FETCH_BLUEPRINT_MYSTERY_BOX_REWARDS_FAIL
  | typeof UPDATE_BLUEPRINT_MYSTERY_BOX_PRICE_START
  | typeof UPDATE_BLUEPRINT_MYSTERY_BOX_PRICE_SUCCESS
  | typeof UPDATE_BLUEPRINT_MYSTERY_BOX_PRICE_FAIL
  | typeof UPDATE_BLUEPRINT_MYSTERY_BOX_REWARDS_START
  | typeof UPDATE_BLUEPRINT_MYSTERY_BOX_REWARDS_SUCCESS
  | typeof UPDATE_BLUEPRINT_MYSTERY_BOX_REWARDS_FAIL;

import React from 'react';
import styles from './Sidebar.module.scss';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {
  faChartLine,
  faUsers,
  faCogs,
  faMoneyBill,
  faComments,
  faTextWidth,
  faBuilding,
  faChartArea,
  faImages,
  faBullhorn,
  faTrophy,
  faTicketAlt,
  faTag,
  faTshirt,
  faBoxOpen,
  faPeopleArrows,
  faRetweet,
  faReceipt,
  faAtom,
  faSignal,
  faCubes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/logo.svg';
import { translate } from '../../utility/messageTranslator/translate';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { routes } from '../../config/Router/routes';
import cx from 'classnames';
import { useIntl } from 'react-intl';

const Sidebar = () => {
  const intl = useIntl();
  const location = useLocation();

  const SIDEBAR_ITEMS = [
    {
      title: 'General',
      items: [
        {
          label: translate(intl, 'SIDEBAR.PRODUCTS', 'Products'),
          to: routes.items,
          icon: faChartLine,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.RESOURCES', 'Resources'),
          to: routes.resources,
          icon: faAtom,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.COLONIST_PARTS', 'Colonist Parts'),
          to: routes.colonistParts,
          icon: faTshirt,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.BLUEPRINTS', 'Blueprints'),
          to: routes.blueprints,
          icon: faBuilding,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.USERS', 'Users'),
          to: routes.users,
          icon: faUsers,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.LEDGER', 'Ledger'),
          to: routes.ledger,
          icon: faMoneyBill,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.SETTINGS', 'Settings'),
          to: routes.settingsEdit,
          icon: faCogs,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.CHAT_MESSAGES', 'Chat messages'),
          to: routes.chatMessages,
          icon: faComments,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.TRANSLATIONS', 'Translations'),
          to: routes.translations,
          icon: faTextWidth,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.STATISTICS', 'Statistics'),
          to: routes.statistics,
          icon: faChartArea,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.HOME_BANNERS', 'Home banners'),
          to: routes.homeBanners,
          icon: faImages,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.CAMPAIGNS', 'Campaigns'),
          to: routes.campaigns,
          icon: faBullhorn,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.DISCOUNT_MCC', 'MCC Discounts'),
          to: routes.mccDiscounts,
          icon: faTag,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.DISCOUNT_LAND', 'Lands Discounts'),
          to: routes.landDiscounts,
          icon: faTicketAlt,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.LEVELS', 'Levels'),
          to: routes.levels,
          icon: faSignal,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.BADGES', 'Badges'),
          to: routes.badges,
          icon: faTrophy,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.BLUEPRINT_MYSTERY_BOX', 'Mystery Box'),
          to: routes.blueprintMysteryBox,
          icon: faBoxOpen,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.AFFILIATE', 'Affiliate'),
          to: routes.affiliate,
          icon: faPeopleArrows,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.JUMP_TASK', 'JumpTask'),
          to: routes.jumpTask,
          icon: faRetweet,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.CRAFTING_RECIPE', 'Crafting Recipe'),
          to: routes.craftingRecipe,
          icon: faReceipt,
          roles: ['ADMIN'],
        },
        {
          label: translate(intl, 'SIDEBAR.MARS_BATTLE_BLOCK', 'Mars Battle Blocks'),
          to: routes.marsBattleBlocks,
          icon: faCubes,
          roles: ['ADMIN'],
        },
      ],
    },
  ];

  const role = localStorage.getItem('role');

  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sidebarContent}>
        <div className={styles.mainContent}>
          <div className={styles.logoContainer}>
            <div className={styles.logo}>
              <Link to="/">
                <img src={logo} alt="Mars4" />
              </Link>
            </div>
          </div>
          <hr className={styles.sidebarDivider} />
          <div className={styles.menuItemsContainer}>
            {SIDEBAR_ITEMS.map((sidebarItem) => (
              <div className={styles.menuItems} key={sidebarItem.title}>
                <div className={styles.menuItemsTitle}>{sidebarItem.title}</div>
                <div className={styles.menuSubItemsContainer}>
                  <div className={styles.menuSubItems}>
                    {sidebarItem.items
                      .filter((navItem) => role && navItem.roles.includes(role))
                      .map((navItem) => (
                        <div key={navItem.to + navItem.label} className={styles.subItemContainer}>
                          <NavLink
                            to={navItem.to}
                            className={cx(styles.subItem, {
                              [styles.activeSubItem]: location.pathname.includes(navItem.to),
                            })}
                          >
                            <FontAwesomeIcon
                              icon={navItem.icon as IconProp}
                              className={styles.navigationIcon}
                              fixedWidth
                            />
                            <div className={styles.navigationText}>{navItem.label}</div>
                          </NavLink>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.bottomScrollContainer}>
          <div className={styles.bottomScroll} />
        </div>
        <div className={styles.rightScrollContainer}>
          <div className={styles.rightScroll} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

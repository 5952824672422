import { HttpError } from '../../config/Axios/axios-instance';
import * as actionTypes from './actionTypes';
import { PropertyActionTypes } from './actionTypes';
import { Property } from '../../domain/Property';

export type PropertyStateType = {
  properties: Property[] | null;
  propertiesLoading: boolean;
  propertiesError: HttpError;
};

export type PropertyActionType = PropertyStateType & {
  type: PropertyActionTypes;
};

export const initialState: PropertyStateType = {
  properties: null,
  propertiesLoading: false,
  propertiesError: null,
};

const fetchPropertiesStart = (state: PropertyStateType): PropertyStateType => ({
  ...state,
  propertiesLoading: true,
});

const fetchPropertiesSuccess = (state: PropertyStateType, action: PropertyActionType): PropertyStateType => ({
  ...state,
  properties: action.properties,
  propertiesLoading: false,
  propertiesError: null,
});

const fetchPropertiesFail = (state: PropertyStateType, action: PropertyActionType): PropertyStateType => ({
  ...state,
  propertiesError: action.propertiesError,
  propertiesLoading: false,
});

const reducer = (state = initialState, action: PropertyActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PROPERTIES_START:
      return fetchPropertiesStart(state);
    case actionTypes.FETCH_PROPERTIES_SUCCESS:
      return fetchPropertiesSuccess(state, action);
    case actionTypes.FETCH_PROPERTIES_FAIL:
      return fetchPropertiesFail(state, action);
    default:
      return state;
  }
};

export default reducer;

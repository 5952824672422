import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../config/Axios/axios-instance';
import { Discount } from '../../domain/Discount';

export type DiscountCreateRequest = {
  code: string;
  discount: number;
  valid_from?: number;
  expires_at: number;
  enabled: boolean;
  scope?: number[];
};

export const fetchMccDiscounts = createAsyncThunk('discount/fetchMccDiscounts', async () => {
  const response = await axiosInstance.get<Discount[]>(`/discount/mcc`);
  return response.data;
});

export const fetchLandDiscounts = createAsyncThunk('discount/fetchLandDiscounts', async () => {
  const response = await axiosInstance.get<Discount[]>(`/discount/land`);
  return response.data;
});

export const fetchMccDiscount = createAsyncThunk('discount/fetchMccDiscount', async (code: string) => {
  const response = await axiosInstance.get<Discount>(`/discount/mcc/${code}`);
  return response.data;
});

export const fetchLandDiscount = createAsyncThunk('discount/fetchLandDiscount', async (code: string) => {
  const response = await axiosInstance.get<Discount>(`/discount/land/${code}`);
  return response.data;
});

export const createMccDiscount = createAsyncThunk(
  'discount/createMccDiscount',
  async (inputs: DiscountCreateRequest) => {
    const response = await axiosInstance.post(`/discount/mcc`, inputs);
    return response.data;
  },
);

export const createLandDiscount = createAsyncThunk(
  'discount/createLandDiscount',
  async (inputs: DiscountCreateRequest) => {
    const response = await axiosInstance.post(`/discount/land`, inputs);
    return response.data;
  },
);

export const updateMccDiscount = createAsyncThunk(
  'discount/updateMccDiscount',
  async (inputs: DiscountCreateRequest) => {
    const response = await axiosInstance.patch<Discount>(`/discount/mcc/${inputs.code}`, inputs);
    return response.data;
  },
);

export const updateLandDiscount = createAsyncThunk(
  'discount/updateLandDiscount',
  async (inputs: DiscountCreateRequest) => {
    const response = await axiosInstance.patch<Discount>(`/discount/land/${inputs.code}`, inputs);
    return response.data;
  },
);

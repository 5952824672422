import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HttpError } from '../../config/Axios/axios-instance';
import { fetchJumpTaskList } from './actions';
import { JumpTaskEntryList } from '../../domain/JumpTaskEntry';

interface JumpTaskStateType {
  jumpTaskList: JumpTaskEntryList | null;
  jumpTaskListLoading: boolean;
  jumpTaskListError: HttpError;
}

const initialState: JumpTaskStateType = {
  jumpTaskList: null,
  jumpTaskListLoading: false,
  jumpTaskListError: null,
};

const jumpTaskSlice = createSlice({
  name: 'jumpTask',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchJumpTaskList.fulfilled.type]: (state, action: PayloadAction<JumpTaskEntryList>) => {
      state.jumpTaskList = action.payload;
      state.jumpTaskListLoading = false;
      state.jumpTaskListError = null;
    },
    [fetchJumpTaskList.pending.type]: (state) => {
      state.jumpTaskListLoading = true;
    },
    [fetchJumpTaskList.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.jumpTaskListLoading = false;
      state.jumpTaskListError = action.payload;
    },
  },
});

export const jumpTaskActions = jumpTaskSlice.actions;

const jumpTaskReducer = jumpTaskSlice.reducer;
export default jumpTaskReducer;

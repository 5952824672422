import { HttpError } from '../../config/Axios/axios-instance';
import * as actionTypes from './actionTypes';
import { Tag } from '../../domain/Tag';
import { TagActionTypes } from './actionTypes';

export type TagStateType = {
  tags: Tag[];
  tagsLoading: boolean;
  tagsError: HttpError;
};

export type TagActionType = TagStateType & {
  type: TagActionTypes;
};

export const initialState: TagStateType = {
  tags: [],
  tagsLoading: false,
  tagsError: null,
};

const fetchTagsStart = (state: TagStateType): TagStateType => ({
  ...state,
  tagsLoading: true,
});

const fetchTagsSuccess = (state: TagStateType, action: TagActionType): TagStateType => ({
  ...state,
  tags: action.tags,
  tagsLoading: false,
  tagsError: null,
});

const fetchTagsFail = (state: TagStateType, action: TagActionType): TagStateType => ({
  ...state,
  tagsError: action.tagsError,
  tagsLoading: false,
});

const reducer = (state = initialState, action: TagActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TAGS_START:
      return fetchTagsStart(state);
    case actionTypes.FETCH_TAGS_SUCCESS:
      return fetchTagsSuccess(state, action);
    case actionTypes.FETCH_TAGS_FAIL:
      return fetchTagsFail(state, action);
    default:
      return state;
  }
};

export default reducer;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HttpError } from '../../config/Axios/axios-instance';
import { createResource, fetchAvailableResources, fetchResource, fetchResourceList, updateResource } from './actions';
import { AvailableResource, Resource, ResourceList } from '../../domain/Resource';

interface ResourceStateType {
  availableResources: AvailableResource[];
  resourceList: ResourceList | null;
  resourceListLoading: boolean;
  resourceListError: HttpError;
  resource: Resource | null;
  resourceLoading: boolean;
  resourceError: HttpError;
  resourceUpdateLoading: boolean;
  resourceUpdateError: HttpError;
  resourceUpdateSuccess: boolean;
  resourceCreateLoading: boolean;
  resourceCreateError: HttpError;
  resourceCreateSuccess: boolean;
  createdResource: Resource | null;
}

const initialState: ResourceStateType = {
  availableResources: [],
  resourceList: null,
  resourceListLoading: false,
  resourceListError: null,
  resource: null,
  resourceLoading: false,
  resourceError: null,
  resourceUpdateLoading: false,
  resourceUpdateError: null,
  resourceUpdateSuccess: false,
  resourceCreateLoading: false,
  resourceCreateError: null,
  resourceCreateSuccess: false,
  createdResource: null,
};

const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAvailableResources.fulfilled.type]: (state, action: PayloadAction<AvailableResource[]>) => {
      state.availableResources = action.payload;
    },
    [fetchResourceList.fulfilled.type]: (state, action: PayloadAction<ResourceList>) => {
      state.resourceList = action.payload;
      state.resourceListLoading = false;
      state.resourceListError = null;
    },
    [fetchResourceList.pending.type]: (state) => {
      state.resourceListLoading = true;
      state.resourceCreateError = null;
      state.resourceUpdateError = null;
      state.resourceCreateLoading = false;
      state.resourceUpdateLoading = false;
      state.resourceCreateSuccess = false;
      state.resourceUpdateSuccess = false;
      state.createdResource = null;
    },
    [fetchResourceList.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.resourceListLoading = false;
      state.resourceListError = action.payload;
    },
    [fetchResource.fulfilled.type]: (state, action: PayloadAction<Resource>) => {
      state.resource = action.payload;
      state.resourceLoading = false;
      state.resourceError = null;
    },
    [fetchResource.pending.type]: (state) => {
      state.resourceLoading = true;
      state.resourceCreateSuccess = false;
      state.resourceUpdateSuccess = false;
      state.resourceUpdateError = null;
      state.resourceCreateError = null;
    },
    [fetchResource.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.resourceLoading = false;
      state.resourceError = action.payload;
    },
    [createResource.fulfilled.type]: (state, action: PayloadAction<Resource>) => {
      state.resourceCreateSuccess = true;
      state.resourceCreateLoading = false;
      state.resourceCreateError = null;
      state.createdResource = action.payload;
    },
    [createResource.pending.type]: (state) => {
      state.resourceCreateLoading = true;
    },
    [createResource.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.resourceCreateLoading = false;
      state.resourceCreateError = action.payload;
    },
    [updateResource.fulfilled.type]: (state) => {
      state.resourceUpdateSuccess = true;
      state.resourceUpdateLoading = false;
      state.resourceUpdateError = null;
    },
    [updateResource.pending.type]: (state) => {
      state.resourceUpdateLoading = true;
    },
    [updateResource.rejected.type]: (state, action: PayloadAction<HttpError>) => {
      state.resourceUpdateLoading = false;
      state.resourceUpdateError = action.payload;
    },
  },
});

export const resourceActions = resourceSlice.actions;

const resourceReducer = resourceSlice.reducer;
export default resourceReducer;

import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/reducer';
import axiosInstance from '../config/Axios/axios-instance';
import * as authService from './auth/service';
import languageReducer from './language/reducer';
import itemReducer from './item/reducer';
import tagReducer from './tag/reducer';
import propertyReducer from './property/reducer';
import settingsReducer from './settings/reducer';
import userReducer from './user/reducer';
import blueprintsReducer from './blueprint/reducer';
import statisticsReducer from './statistics/reducer';
import homeBannerReducer from './homeBanner/reducer';
import campaignReducer from './campaign/reducer';
import discountReducer from './discount/reducer';
import badgeReducer from './badge/reducer';
import blueprintMysteryBoxReducer from './blueprintMysteryBox/reducer';
import colonistPartReducer from './colonistPart/reducer';
import affiliateReducer from './affiliate/reducer';
import jumpTaskReducer from './jumpTask/reducer';
import userDetailsReducer from './userDetails/reducer';
import craftingRecipeReducer from './craftingRecipe/reducer';
import resourceReducer from './resource/reducer';
import levelReducer from './levels/reducer';
import marsBattleBlockReducer from './marsBattleBlock/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  language: languageReducer,
  item: itemReducer,
  user: userReducer,
  tag: tagReducer,
  properties: propertyReducer,
  settings: settingsReducer,
  blueprint: blueprintsReducer,
  statistics: statisticsReducer,
  homeBanner: homeBannerReducer,
  campaign: campaignReducer,
  discountReducer,
  badge: badgeReducer,
  blueprintMysteryBox: blueprintMysteryBoxReducer,
  colonistPart: colonistPartReducer,
  affiliateReducer,
  jumpTaskReducer,
  userDetails: userDetailsReducer,
  craftingRecipe: craftingRecipeReducer,
  resource: resourceReducer,
  levels: levelReducer,
  marsBattleBlock: marsBattleBlockReducer,
});

const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

const { dispatch } = store;
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const requestUrl = `${error.response.config.url}`;
    const isRefresh = requestUrl.includes('/auth/refresh');
    const isLogout = requestUrl.includes('/auth/logout');
    const isAuthRequest = isRefresh || isLogout;

    if (isAuthRequest) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && error.response.config.url !== '/token') {
      if (!originalRequest._retry) {
        originalRequest._retry = true;

        const authToken = await authService.refresh();
        if (!authToken) {
          dispatch(authService.logoutUser());
          return Promise.reject(error);
        }

        originalRequest.headers['Authorization'] = `Bearer ${authToken}`;
        return axiosInstance(originalRequest);
      } else {
        dispatch(authService.logoutUser());
      }
    }

    return Promise.reject(error);
  },
);

type Props = {
  children?: ReactNode;
};

const StoreProvider = ({ children }: Props) => <Provider store={store}>{children}</Provider>;

export default StoreProvider;
